import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Card, Button, CardDeck, CardBody } from 'reactstrap';

class AddReviewSourceForm extends Form {
  state = {
    data: { name: '', displayReviewScores: '', isActive: '' },
    displayReviewOptions: [
      { _id: 'new-yes', name: 'Yes' },
      { _id: 'new-no', name: 'No' },
    ],
    displayReviewScoresOptions: [
      { _id: 'new-yes', name: 'Yes' },
      { _id: 'new-no', name: 'No' },
    ],
    statusOptions: [
      { _id: 'new-active', name: 'Active' },
      { _id: 'new-inactive', name: 'Inactive' },
    ],
    errors: {},
  };

  schema = {
    name: Joi.string().min(1).max(255).required().label('Name'),
    displayReview: Joi.string().required().label('Show Review'),
    displayReviewScores: Joi.string().required().label('Show Review Score'),
    isActive: Joi.string().required().label('Status'),
  };

  doSubmit = async () => {
    let { data } = this.state;

    data.displayReview === 'new-yes'
      ? (data.displayReview = true)
      : (data.displayReview = false);

    data.displayReviewScores === 'new-yes'
      ? (data.displayReviewScores = true)
      : (data.displayReviewScores = false);

    data.isActive === 'new-active'
      ? (data.isActive = true)
      : (data.isActive = false);

    this.setState({ data: { name: '', displayReview: '', displayReviewScores: '', isActive: '' } });
    this.props.onAdd(data);
  };

  handleAddButtonClick = (setting) => {
    this.props.onButtonClick(setting);
  };

  render() {
    const { showAddReviewSourceForm, buttonTitle } = this.props;

    if (showAddReviewSourceForm)
      return (
        <>
          <div className='row mt-4'>
            <div className='col'>
              <Button
                className='float-end'
                color='primary'
                size='sm'
                onClick={() => this.handleAddButtonClick(false)}
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className='row mt-4'>
          <div className='col'>
            <CardDeck>
              <Card className='shadow'>
                <CardBody>
                  <h4>Adding new review source</h4>
                  <form className='mt-4' onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                      <div className='col'>
                        {this.renderInput('name', 'Review source name')}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'displayReview',
                          'Show Review?',
                          this.state.displayReviewOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'displayReviewScores',
                          'Show Review Score?',
                          this.state.displayReviewScoresOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'isActive',
                          'Status',
                          this.state.statusOptions
                        )}
                      </div>
                    </div>
                    {this.renderButton('Add', 'btn-success btn-sm float-end')}
                    <Button
                      className='me-2 float-end'
                      size='sm'
                      onClick={() => this.handleAddButtonClick(true)}
                    >
                      Cancel
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </CardDeck>
          </div>
        </div>
      </>
    );
  }
}

export default AddReviewSourceForm;
