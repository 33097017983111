import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link } from 'react-router-dom';
import VehicleData from './vehicleData';
import { Button, Row, Col } from 'reactstrap';

export default function ManageVehicleData() {
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [showVehicleSelect, setShowVehicleSelect] = useState(true);
  const [currentManufacturerId, setCurrentManufacturerId] = useState(
    '5c45a2f92e86592f90baf93c'
  );
  const [currentModelId, setCurrentModelId] = useState('');
  const [prodStatusFilter, setProdStatusFilter] = useState('all');
  const [completeStatusFilter, setCompleteStatusFilter] = useState('all');

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingManufacturers,
    error: errorManufacturers,
    data: dataManufacturers,
  } = useQuery(gql`
    query {
      manufacturers(query: { isPublished_ne: false }, sortBy: NAME_ASC) {
        _id
        name
      }
    }
  `);

  const {
    loading: loadingModels,
    error: errorModels,
    data: dataModels,
    refetch: fetchModels,
  } = useQuery(
    gql`
      query($mId: ObjectId) {
        models(
          query: { manufacturerId: $mId, isActive: true }
          sortBy: NAME_ASC
        ) {
          _id
          name
          year
          vehicleTypes
          isActive
          isComplete
          prodStatus
        }
      }
    `,
    { variables: { mId: '5c45a2f92e86592f90baf93c' } }
  );

  useEffect(() => {
    if (loadingManufacturers === false && dataManufacturers) {
      setManufacturers(dataManufacturers.manufacturers);
      setCurrentManufacturerId(dataManufacturers.manufacturers[0]._id);
    }
  }, [loadingManufacturers, dataManufacturers]);

  useEffect(() => {
    if (loadingModels === false && dataModels) {
      setModels(dataModels.models);
    }
  }, [loadingModels, dataModels]);

  if (errorManufacturers) {
    return <div>encountered an error: {errorManufacturers}</div>;
  }

  if (errorModels) {
    return <div>encountered an error: {errorModels}</div>;
  }

  async function handleManufacturerChange(event) {
    setCurrentManufacturerId(event.target.value);
    fetchModels({ mId: String(event.target.value) });
  }

  async function handleProdStatusFilterChange(event) {
    setCurrentModelId('');
    setProdStatusFilter(event.target.value);
  }
  async function handleCompleteStatusFilterChange(event) {
    setCurrentModelId('');
    setCompleteStatusFilter(event.target.value);
  }

  function handleModelChange(event) {
    if (event.target.value === '0') {
      setCurrentModelId('');
      return;
    }

    setCurrentModelId(event.target.value);
  }

  async function handleButtonClick(result) {
    if (currentModelId === '') return;
    setShowVehicleSelect(result === 'true' ? true : false);
  }

function displayfilteredModelList() {

  var unfilteredModels = JSON.parse(JSON.stringify(models));
  var prodStatusFilteredModelList = [];

  if(prodStatusFilter === 'all') {
    prodStatusFilteredModelList = JSON.parse(JSON.stringify(models));
  }

  if(prodStatusFilter === 'current') {
    prodStatusFilteredModelList = unfilteredModels.filter(function (thisModel) {
      return thisModel.prodStatus === 'current';
    });
  }
  if(prodStatusFilter === 'discontinued') {
    prodStatusFilteredModelList = unfilteredModels.filter(function (thisModel) {
      return thisModel.prodStatus === 'discontinued';
    });
  }
  
  var completeStatusFilteredModelList = JSON.parse(JSON.stringify(prodStatusFilteredModelList));

  if(completeStatusFilter === 'true') {
    completeStatusFilteredModelList = prodStatusFilteredModelList.filter(function (thisModel) {
      return thisModel.isComplete === true;
    });
  }
  if(completeStatusFilter === 'false') {
    completeStatusFilteredModelList = prodStatusFilteredModelList.filter(function (thisModel) {
      return thisModel.isComplete === false;
    });
  }

  var filteredModelsList = '';

  filteredModelsList = completeStatusFilteredModelList.map((thisModel) => 
    (<option key={thisModel._id} value={thisModel._id}>
    {thisModel.name} ({thisModel.year})
  </option>)
  );

  return filteredModelsList;

}


  if (showVehicleSelect)
    return (
      <>
        <p>
          Select a vehicle make and model below to view the vehicle data. If the
          expected vehicle does not exist in the list, an Administrator can add
          it in <Link to='/configure-models'>Configure Models</Link>.
        </p>

        <div
          className='card card-body bg-white mt-3 shadow mx-auto'
          style={{ minWidth: 400, maxWidth: 'fit-content' }}
        >
          <Row>
            <Col>
              <p>
                <strong>Manufacturer</strong>
              </p>
              {loadingManufacturers ? (
                <ClipLoader
                  loading={true}
                  cssOverride={cssoverride}
                />
              ) : (
                <div className='form-group mb-0'>
                  <select
                    className='form-control mb-3'
                    onChange={handleManufacturerChange}
                    value={currentManufacturerId}
                  >
                    {manufacturers.map((manufacturer) => (
                      <option key={manufacturer._id} value={manufacturer._id}>
                        {manufacturer.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <p className='mt-3'>
                <strong>Production status filter</strong>
              </p>
              <div className='form-group mb-0'>
                <select
                  className='form-control mb-3'
                  onChange={handleProdStatusFilterChange}
                  value={prodStatusFilter}
                >
                  <option key='show-prodstatus-all' value='all'>
                    Show all
                  </option>
                  <option key='show-prodstatus-current' value='current'>
                    Show current only
                  </option>
                  <option key='show-prodstatus-incomplete' value='discontinued'>
                    Show discontinued only
                  </option>
                </select>
              </div>
              <p className='mt-3'>
                <strong>Complete status filter</strong>
              </p>
              <div className='form-group mb-0'>
                <select
                  className='form-control mb-3'
                  onChange={handleCompleteStatusFilterChange}
                  value={completeStatusFilter}
                >
                  <option key='show-all' value='all'>
                    Show all
                  </option>
                  <option key='show-complete' value='true'>
                    Show complete only
                  </option>
                  <option key='show-incomplete' value='false'>
                    Show incomplete only
                  </option>
                </select>
              </div>
              <p className='mt-3'>
                <strong>
                  Vehicle model{models.length > 1 ? 's' : ''} to view
                </strong>
              </p>
              <div className='form-group mb-0'>
                <select
                  className='form-control mb-3'
                  onChange={handleModelChange}
                  value={currentModelId}
                >
                  <option key='0' value='0' />
                  {displayfilteredModelList()}
                </select>
              </div>

              {models.length > 0 && (
                <Button
                  className=''
                  color='primary'
                  size='lg'
                  onClick={() => handleButtonClick('false')}
                  block
                >
                  Go!
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );

  return (
    <>
      <div className='row'>
        <div className='col'>
          <Button
            className='mb-3'
            outline
            color='primary'
            size='sm'
            onClick={() => handleButtonClick('true')}
          >
            {`<-`} Select another vehicle
          </Button>
        </div>
      </div>
      <VehicleData
        modelId={currentModelId}
        manufacturerName={
          manufacturers.filter((item) => item._id === currentManufacturerId)[0]
            .name
        }
      />
    </>
  );
}
