import { Route, Switch, Redirect, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import NavBar from "./components/navbar";
import Logout from "./components/logout";
import Home from "./components/home";
import ManageVehicleData from "./components/manageVehicleData";
import ConfigureVehicles from "./components/configureModels";
import ConfigureReviewSources from "./components/configureReviewSources";
import ManagePluginSites from "./components/managePluginSites";
import NotFound from "./components/notFound";
import ProtectedRoute from "./components/common/protectedRoute";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
import ViewReports from "./components/viewReports";
import { useRealmApp } from "./RealmApp";

export default function App() {
  const app = useRealmApp();
  const iAmHere = document.location.pathname;
  const user = app.currentUser;
  // console.log('in app--user: ', app.currentUser._profile.data.email);

  return (
    <>
      <ToastContainer />
      <NavBar />
      {user && (
        <Breadcrumb>
          {iAmHere !== "/home" && (
            <BreadcrumbItem tag={Link} to="/home">
              Home
            </BreadcrumbItem>
          )}
          {iAmHere === "/manage-vehicle-data" ? (
            <BreadcrumbItem active>Manage Vehicle Data</BreadcrumbItem>
          ) : (
            ""
          )}
          {iAmHere === "/configure-models" ? (
            <BreadcrumbItem active>Configure Models</BreadcrumbItem>
          ) : (
            ""
          )}
          {iAmHere === "/configure-review-sources" ? (
            <BreadcrumbItem active>Configure Review Sources</BreadcrumbItem>
          ) : (
            ""
          )}
          {iAmHere === "/view-reports" ? (
            <BreadcrumbItem active>View Reports</BreadcrumbItem>
          ) : (
            ""
          )}
          {iAmHere === "/manage-plugin-sites" ? (
            <BreadcrumbItem active>Manage WP Plugin Sites</BreadcrumbItem>
          ) : (
            ""
          )}
        </Breadcrumb>
      )}
      <main className="container">
        <Switch>
          {/* <Route path='/login' component={LoginForm} /> */}
          <Route path="/logout" component={Logout} />
          <ProtectedRoute
            path="/manage-vehicle-data"
            component={ManageVehicleData}
          />
          <ProtectedRoute
            path="/configure-models"
            component={ConfigureVehicles}
          />
          <ProtectedRoute
            path="/configure-review-sources"
            component={ConfigureReviewSources}
          />
          <ProtectedRoute path="/view-reports" component={ViewReports} />
          <ProtectedRoute
            path="/manage-plugin-sites"
            component={ManagePluginSites}
          />
          <ProtectedRoute path="/home" component={Home} />
          <ProtectedRoute path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="/home" />
          <Redirect to="/not-found" />
        </Switch>
      </main>
    </>
  );
}
