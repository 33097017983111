import React from 'react';
import * as Realm from 'realm-web';
import { useRealmApp } from '../RealmApp';
import '../custom.css';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import validator from 'validator';

export default function LoginScreen() {
  const app = useRealmApp();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState({});

  const handleLogin = async () => {
    setError((e) => ({ ...e, password: null }));
    try {
      await app.logIn(Realm.Credentials.emailPassword(email, password));
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  };

  return (
    <>
      <h1 className='h3 mt-5 mb-5 font-weight-normal text-center'>PRISM</h1>
      <Form className='form-signin'>
        <FormGroup>
          <Label for='userEmail'>Email</Label>
          <Input
            id='userEmail'
            type='email'
            label='Email'
            placeholder='your.email@example.com'
            onChange={(e) => {
              setError((e) => ({ ...e, email: null }));
              setEmail(e.target.value);
            }}
            value={email}
            state={
              error.email
                ? 'error'
                : validator.isEmail(email)
                ? 'valid'
                : 'none'
            }
          />
          {error && <p style={{ color: 'red' }}>{error.email}</p>}
        </FormGroup>
        <FormGroup>
          <Label for='userPassword'>Password</Label>
          <Input
            id='userPassword'
            type='password'
            label='Password'
            placeholder='pa55w0rd'
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            state={error.password ? 'error' : error.password ? 'valid' : 'none'}
          />
          {error && <p style={{ color: 'red' }}>{error.password}</p>}
        </FormGroup>
        <Button color='primary' size='lg' block onClick={() => handleLogin()}>
          Log In
        </Button>
      </Form>
    </>
  );
}

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case 'invalid username':
      setError((prevErr) => ({ ...prevErr, email: 'Invalid email address.' }));
      break;
    case 'invalid username/password':
    case 'invalid password':
    case '401':
      setError((err) => ({ ...err, password: 'Incorrect password.' }));
      break;
    case 'name already in use':
    case '409':
      setError((err) => ({ ...err, email: 'Email is already registered.' }));
      break;
    case 'password must be between 6 and 128 characters':
    case '400':
      setError((err) => ({
        ...err,
        password: 'Password must be between 6 and 128 characters.',
      }));
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(':');
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: '', message: '' };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}
