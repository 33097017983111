import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Card, Button, CardDeck, CardBody } from 'reactstrap';

class EditReviewSourceForm extends Form {
  state = {
    showDeleteConfirm: false,
    data: { name: '', logo: '', displayReviewScores: '', isActive: '' },
    displayReviewOptions: [
      { _id: 'edit-yes', name: 'Yes' },
      { _id: 'edit-no', name: 'No' },
    ],
    displayReviewScoresOptions: [
      { _id: 'edit-yes', name: 'Yes' },
      { _id: 'edit-no', name: 'No' },
    ],
    statusOptions: [
      { _id: 'edit-active', name: 'Active' },
      { _id: 'edit-inactive', name: 'Inactive' },
    ],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().min(1).max(255).required().label('Name'),
    logo: Joi.string().min(0).max(255).label('Logo'),
    displayReview: Joi.string().required().label('Show Review'),
    displayReviewScores: Joi.string().required().label('Show Review Score'),
    isActive: Joi.string().required().label('Status'),
  };

  async populateReviewSource() {
    const revSource = this.props.reviewSource;
    this.setState({ data: this.mapToViewModel(revSource) });
  }

  mapToViewModel(reviewSource) {
    return {
      _id: reviewSource._id,
      name: reviewSource.name,
      logo: reviewSource.logo ? reviewSource.logo : '',
      displayReview: reviewSource.displayReview ? 'edit-yes' : 'edit-no',
      displayReviewScores: reviewSource.displayReviewScores ? 'edit-yes' : 'edit-no',
      isActive: reviewSource.isActive ? 'edit-active' : 'edit-inactive',
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.populateReviewSource();
  }

  doSubmit = async () => {
    let { data } = this.state;

  data.displayReview === 'edit-yes'
    ? (data.displayReview = true)
    : (data.displayReview = false);  

    data.displayReviewScores === 'edit-yes'
      ? (data.displayReviewScores = true)
      : (data.displayReviewScores = false);

    data.isActive === 'edit-active'
      ? (data.isActive = true)
      : (data.isActive = false);

    this.props.onSave(data);
  };

  handleEditCancelBtnClick = () => {
    this.props.onCancel();
  };

  handleDeleteBtnClick = () => {
    this.setState({ showDeleteConfirm: true });
  };

  handleDeleteConfirmBtnYesClick = async () => {
    console.log('deleting: ', this.props.reviewSourceId);
    this.props.onDelete(this.props.reviewSourceId);
  };

  handleDeleteConfirmBtnNoClick = () => {
    this.setState({ showDeleteConfirm: false });
  };

  render() {
    const { data, showDeleteConfirm, displayReviewOptions, displayReviewScoresOptions, statusOptions } = this.state;

    return (
      <>
        <div className='row mt-4'>
          <div className='col'>
            <CardDeck>
              <Card className='shadow'>
                <CardBody>
                  <h4>
                    Editing <strong>{data.name}</strong>
                  </h4>
                  <form className='mt-4' onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                      <div className='col'>
                        {this.renderInput('name', 'Review source name')}
                      </div>
                      <div className='col'>
                        {this.renderInput('logo', 'Review source logo')}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'displayReview',
                          'Show Review?',
                          displayReviewOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'displayReviewScores',
                          'Show Review Score?',
                          displayReviewScoresOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect('isActive', 'Status', statusOptions)}
                      </div>
                    </div>
                    <div className='form-group'>
                      {!showDeleteConfirm && (
                        <>
                          {this.renderButton(
                            'Update',
                            'btn-success btn-sm float-end mb-3'
                          )}
                          <Button
                            className='me-2 float-end'
                            size='sm'
                            onClick={() => this.handleEditCancelBtnClick()}
                          >
                            Cancel
                          </Button>
                          <Button
                            className='me-3 mb-3 float-end'
                            size='sm'
                            color='danger'
                            onClick={() => this.handleDeleteBtnClick()}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {showDeleteConfirm && (
                        <>
                          <Button
                            className='mb-3 float-end'
                            size='sm'
                            outline
                            color='secondary'
                            onClick={() => this.handleDeleteConfirmBtnNoClick()}
                          >
                            No
                          </Button>
                          <Button
                            className='me-3 mb-3 float-end'
                            size='sm'
                            outline
                            color='danger'
                            onClick={() =>
                              this.handleDeleteConfirmBtnYesClick()
                            }
                          >
                            Yes
                          </Button>
                          <span className='text-danger me-2 mb-3 float-end'>
                            Are you sure you want to delete this and all
                            associated reviews?
                          </span>
                        </>
                      )}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </CardDeck>
          </div>
        </div>
      </>
    );
  }
}

export default EditReviewSourceForm;
