import gql from 'graphql-tag';

const GET_GREENNCAPS_FOR_MODEL = gql`
  query GetGreenNCAPsForModel($modelId: ObjectId) {
    greenncaps(query: { modelId: $modelId }, sortBy: DATE_DESC) {
      _id
      cleanAirIndex
      date
      energyEfficiencyIndex
      greenhouseGasIndex
      isValid
      modelId
      modelReviewed
      starRating
      url
    }
  }
`;

const ADD_GREENNCAP = gql`
  mutation AddGreenNCAP(
    $cleanAirIndex: Float
    $date: DateTime
    $energyEfficiencyIndex: Float
    $greenhouseGasIndex: Float
    $isValid: Boolean
    $modelId: ObjectId
    $modelReviewed: String
    $starRating: Float
    $url: String
  ) {
    insertOneGreenncap(
      data: {
        cleanAirIndex: $cleanAirIndex
        date: $date
        energyEfficiencyIndex: $energyEfficiencyIndex
        greenhouseGasIndex: $greenhouseGasIndex
        isValid: $isValid
        modelId: $modelId
        modelReviewed: $modelReviewed
        starRating: $starRating
        url: $url
      }
    ) {
      _id
      url
    }
  }
`;

const UPDATE_GREENNCAP = gql`
  mutation UpdateGreenNCAP(
    $greenncapId: ObjectId
    $cleanAirIndex: Float
    $date: DateTime
    $energyEfficiencyIndex: Float
    $greenhouseGasIndex: Float
    $isValid: Boolean
    $modelId: ObjectId
    $modelReviewed: String
    $starRating: Float
    $url: String
  ) {
    updatedGreenNCAP: updateOneGreenncap(
      query: { _id: $greenncapId }
      set: {
        cleanAirIndex: $cleanAirIndex
        date: $date
        energyEfficiencyIndex: $energyEfficiencyIndex
        greenhouseGasIndex: $greenhouseGasIndex
        isValid: $isValid
        modelId: $modelId
        modelReviewed: $modelReviewed
        starRating: $starRating
        url: $url
      }
    ) {
      _id
      url
    }
  }
`;

const DELETE_GREENNCAP = gql`
  mutation DeleteGreenNCAP($greenncapId: ObjectId!) {
    deletedGreenNCAP: deleteOneGreenncap(query: { _id: $greenncapId }) {
      _id
      url
    }
  }
`;

export {
  GET_GREENNCAPS_FOR_MODEL,
  ADD_GREENNCAP,
  UPDATE_GREENNCAP,
  DELETE_GREENNCAP,
};
