import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Row, Table, Button } from 'reactstrap';

class EditRunningCostsForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      data: {
        rating: '',
      },
      errors: {},
    };
  }

  schema = {
    modelId: Joi.string(),
    rcDieselCO2Avg: Joi.number().integer().allow('').allow(null).label('Diesel — CO2 average'),
    rcDieselCO2Min: Joi.number().integer().allow('').allow(null).label('Diesel — CO2 min'),
    rcDieselCO2Max: Joi.number().integer().allow('').allow(null).label('Diesel — CO2 max'),
    rcDieselMPGAvg: Joi.number().integer().allow('').allow(null).label('Diesel — MPG average'),
    rcDieselMPGMin: Joi.number().integer().allow('').allow(null).label('Diesel — MPG min'),
    rcDieselMPGMax: Joi.number().integer().allow('').allow(null).label('Diesel — MPG max'),
    rcPetrolCO2Avg: Joi.number().integer().allow('').allow(null).label('Petrol — CO2 average'),
    rcPetrolCO2Min: Joi.number().integer().allow('').allow(null).label('Petrol — CO2 min'),
    rcPetrolCO2Max: Joi.number().integer().allow('').allow(null).label('Petrol — CO2 max'),
    rcPetrolMPGAvg: Joi.number().integer().allow('').allow(null).label('Petrol — MPG average'),
    rcPetrolMPGMin: Joi.number().integer().allow('').allow(null).label('Petrol — MPG min'),
    rcPetrolMPGMax: Joi.number().integer().allow('').allow(null).label('Petrol — MPG max'),
    rcHybridCO2Avg: Joi.number().integer().allow('').allow(null).label('Hybrid — CO2 average'),
    rcHybridCO2Min: Joi.number().integer().allow('').allow(null).label('Hybrid — CO2 min'),
    rcHybridCO2Max: Joi.number().integer().allow('').allow(null).label('Hybrid — CO2 max'),
    rcHybridMPGAvg: Joi.number().integer().allow('').allow(null).label('Hybrid — MPG average'),
    rcHybridMPGMin: Joi.number().integer().allow('').allow(null).label('Hybrid — MPG min'),
    rcHybridMPGMax: Joi.number().integer().allow('').allow(null).label('Hybrid — MPG max'),
    rcPlugInHybridCO2Avg: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — CO2 average'),
    rcPlugInHybridCO2Min: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — CO2 min'),
    rcPlugInHybridCO2Max: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — CO2 max'),
    rcPlugInHybridBatteryRangeAvg: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — battery range average'),
    rcPlugInHybridBatteryRangeMin: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — battery range min'),
    rcPlugInHybridBatteryRangeMax: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — battery range max'),
    rcPlugInHybridMPGAvg: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — MPG average'),
    rcPlugInHybridMPGMin: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — MPG min'),
    rcPlugInHybridMPGMax: Joi.number().integer().allow('').allow(null).label('PlugInHybrid — MPG max'),
    rcEVMpKWhAvg: Joi.number().precision(1).allow('').allow(null).label('EV — m/KWh average'),
    rcEVMpKWhMin: Joi.number().precision(1).allow('').allow(null).label('EV — m/KWh min'),
    rcEVMpKWhMax: Joi.number().precision(1).allow('').allow(null).label('EV — m/KWh max'),
    rcEVBatteryRangeAvg: Joi.number().integer().allow('').allow(null).label('EV — battery range average'),
    rcEVBatteryRangeMin: Joi.number().integer().allow('').allow(null).label('EV — battery range min'),
    rcEVBatteryRangeMax: Joi.number().integer().allow('').allow(null).label('EV — battery range max'),
    rcInsuranceGroupAvg: Joi.number().integer().allow('').allow(null).label('Insurance group — average'),
    rcInsuranceGroupMin: Joi.number().integer().allow('').allow(null).label('Insurance group — min'),
    rcInsuranceGroupMax: Joi.number().integer().allow('').allow(null).label('Insurance group — max'),
    rcAvgSMR1Year: Joi.number().integer().allow('').allow(null).label('Average SMR for 1 year'),    
    rcAvgSMR2Years: Joi.number().integer().allow('').allow(null).label('Average SMR for 2 years'),    
    rcAvgSMR3Years: Joi.number().integer().allow('').allow(null).label('Average SMR for 3 years'),    
    rcAvgSMR4Years: Joi.number().integer().allow('').allow(null).label('Average SMR for 4 years'),    
    rcAvgSMR5Years: Joi.number().integer().allow('').allow(null).label('Average SMR for 5 years'),    
  };

  async componentDidMount() {
    this.setState({
      data: this.mapToViewModel(this.state.model)
    });
  }

  mapToViewModel(model) {
    return {
      modelId: model._id,
      rcDieselCO2Avg: model.rcDieselCO2Avg,
      rcDieselCO2Min: model.rcDieselCO2Min,
      rcDieselCO2Max: model.rcDieselCO2Max,
      rcDieselMPGAvg: model.rcDieselMPGAvg,
      rcDieselMPGMin: model.rcDieselMPGMin,
      rcDieselMPGMax: model.rcDieselMPGMax,
      rcPetrolCO2Avg: model.rcPetrolCO2Avg,
      rcPetrolCO2Min: model.rcPetrolCO2Min,
      rcPetrolCO2Max: model.rcPetrolCO2Max,
      rcPetrolMPGAvg: model.rcPetrolMPGAvg,
      rcPetrolMPGMin: model.rcPetrolMPGMin,
      rcPetrolMPGMax: model.rcPetrolMPGMax,
      rcHybridCO2Avg: model.rcHybridCO2Avg,
      rcHybridCO2Min: model.rcHybridCO2Min,
      rcHybridCO2Max: model.rcHybridCO2Max,
      rcHybridMPGAvg: model.rcHybridMPGAvg,
      rcHybridMPGMin: model.rcHybridMPGMin,
      rcHybridMPGMax: model.rcHybridMPGMax,
      rcPlugInHybridCO2Avg: model.rcPlugInHybridCO2Avg,
      rcPlugInHybridCO2Min: model.rcPlugInHybridCO2Min,
      rcPlugInHybridCO2Max: model.rcPlugInHybridCO2Max,
      rcPlugInHybridBatteryRangeAvg: model.rcPlugInHybridBatteryRangeAvg,
      rcPlugInHybridBatteryRangeMin: model.rcPlugInHybridBatteryRangeMin,
      rcPlugInHybridBatteryRangeMax: model.rcPlugInHybridBatteryRangeMax,
      rcPlugInHybridMPGAvg: model.rcPlugInHybridMPGAvg,
      rcPlugInHybridMPGMin: model.rcPlugInHybridMPGMin,
      rcPlugInHybridMPGMax: model.rcPlugInHybridMPGMax,
      rcEVMpKWhAvg: model.rcEVMpKWhAvg,
      rcEVMpKWhMin: model.rcEVMpKWhMin,
      rcEVMpKWhMax: model.rcEVMpKWhMax,
      rcEVBatteryRangeAvg: model.rcEVBatteryRangeAvg,
      rcEVBatteryRangeMin: model.rcEVBatteryRangeMin,
      rcEVBatteryRangeMax: model.rcEVBatteryRangeMax,
      rcInsuranceGroupAvg: model.rcInsuranceGroupAvg,
      rcInsuranceGroupMin: model.rcInsuranceGroupMin,
      rcInsuranceGroupMax: model.rcInsuranceGroupMax,
      rcAvgSMR1Year: model.rcAvgSMR1Year,    
      rcAvgSMR2Years: model.rcAvgSMR2Years,    
      rcAvgSMR3Years: model.rcAvgSMR3Years,    
      rcAvgSMR4Years: model.rcAvgSMR4Years,    
      rcAvgSMR5Years: model.rcAvgSMR5Years,    
    };
  }

  doSubmit = async () => {
    let { data } = this.state;

    data.rcDieselCO2Avg = (!isNaN(parseInt(data.rcDieselCO2Avg)) ? parseInt(data.rcDieselCO2Avg) : null);
    data.rcDieselCO2Min = (!isNaN(parseInt(data.rcDieselCO2Min)) ? parseInt(data.rcDieselCO2Min) : null);
    data.rcDieselCO2Max = (!isNaN(parseInt(data.rcDieselCO2Max)) ? parseInt(data.rcDieselCO2Max) : null);
    data.rcDieselMPGAvg = (!isNaN(parseInt(data.rcDieselMPGAvg)) ? parseInt(data.rcDieselMPGAvg) : null);
    data.rcDieselMPGMin = (!isNaN(parseInt(data.rcDieselMPGMin)) ? parseInt(data.rcDieselMPGMin) : null);
    data.rcDieselMPGMax = (!isNaN(parseInt(data.rcDieselMPGMax)) ? parseInt(data.rcDieselMPGMax) : null);
    data.rcPetrolCO2Avg = (!isNaN(parseInt(data.rcPetrolCO2Avg)) ? parseInt(data.rcPetrolCO2Avg) : null);
    data.rcPetrolCO2Min = (!isNaN(parseInt(data.rcPetrolCO2Min)) ? parseInt(data.rcPetrolCO2Min) : null);
    data.rcPetrolCO2Max = (!isNaN(parseInt(data.rcPetrolCO2Max)) ? parseInt(data.rcPetrolCO2Max) : null);
    data.rcPetrolMPGAvg = (!isNaN(parseInt(data.rcPetrolMPGAvg)) ? parseInt(data.rcPetrolMPGAvg) : null);
    data.rcPetrolMPGMin = (!isNaN(parseInt(data.rcPetrolMPGMin)) ? parseInt(data.rcPetrolMPGMin) : null);
    data.rcPetrolMPGMax = (!isNaN(parseInt(data.rcPetrolMPGMax)) ? parseInt(data.rcPetrolMPGMax) : null);
    data.rcHybridCO2Avg = (!isNaN(parseInt(data.rcHybridCO2Avg)) ? parseInt(data.rcHybridCO2Avg) : null);
    data.rcHybridCO2Min = (!isNaN(parseInt(data.rcHybridCO2Min)) ? parseInt(data.rcHybridCO2Min) : null);
    data.rcHybridCO2Max = (!isNaN(parseInt(data.rcHybridCO2Max)) ? parseInt(data.rcHybridCO2Max) : null);
    data.rcHybridMPGAvg = (!isNaN(parseInt(data.rcHybridMPGAvg)) ? parseInt(data.rcHybridMPGAvg) : null);
    data.rcHybridMPGMin = (!isNaN(parseInt(data.rcHybridMPGMin)) ? parseInt(data.rcHybridMPGMin) : null);
    data.rcHybridMPGMax = (!isNaN(parseInt(data.rcHybridMPGMax)) ? parseInt(data.rcHybridMPGMax) : null);
    data.rcPlugInHybridCO2Avg = (!isNaN(parseInt(data.rcPlugInHybridCO2Avg)) ? parseInt(data.rcPlugInHybridCO2Avg) : null);
    data.rcPlugInHybridCO2Min = (!isNaN(parseInt(data.rcPlugInHybridCO2Min)) ? parseInt(data.rcPlugInHybridCO2Min) : null);
    data.rcPlugInHybridCO2Max = (!isNaN(parseInt(data.rcPlugInHybridCO2Max)) ? parseInt(data.rcPlugInHybridCO2Max) : null);
    data.rcPlugInHybridBatteryRangeAvg = (!isNaN(parseInt(data.rcPlugInHybridBatteryRangeAvg)) ? parseInt(data.rcPlugInHybridBatteryRangeAvg) : null);
    data.rcPlugInHybridBatteryRangeMin = (!isNaN(parseInt(data.rcPlugInHybridBatteryRangeMin)) ? parseInt(data.rcPlugInHybridBatteryRangeMin) : null);
    data.rcPlugInHybridBatteryRangeMax = (!isNaN(parseInt(data.rcPlugInHybridBatteryRangeMax)) ? parseInt(data.rcPlugInHybridBatteryRangeMax) : null);
    data.rcPlugInHybridMPGAvg = (!isNaN(parseInt(data.rcPlugInHybridMPGAvg)) ? parseInt(data.rcPlugInHybridMPGAvg) : null);
    data.rcPlugInHybridMPGMin = (!isNaN(parseInt(data.rcPlugInHybridMPGMin)) ? parseInt(data.rcPlugInHybridMPGMin) : null);
    data.rcPlugInHybridMPGMax = (!isNaN(parseInt(data.rcPlugInHybridMPGMax)) ? parseInt(data.rcPlugInHybridMPGMax) : null);
    data.rcEVMpKWhAvg = (!isNaN(parseFloat(data.rcEVMpKWhAvg)) ? parseFloat(parseFloat(data.rcEVMpKWhAvg).toFixed(1)) : null);
    data.rcEVMpKWhMin = (!isNaN(parseFloat(data.rcEVMpKWhMin)) ? parseFloat(parseFloat(data.rcEVMpKWhMin).toFixed(1)) : null);
    data.rcEVMpKWhMax = (!isNaN(parseFloat(data.rcEVMpKWhMax)) ? parseFloat(parseFloat(data.rcEVMpKWhMax).toFixed(1)) : null);
    data.rcEVBatteryRangeAvg = (!isNaN(parseInt(data.rcEVBatteryRangeAvg)) ? parseInt(data.rcEVBatteryRangeAvg) : null);
    data.rcEVBatteryRangeMin = (!isNaN(parseInt(data.rcEVBatteryRangeMin)) ? parseInt(data.rcEVBatteryRangeMin) : null);
    data.rcEVBatteryRangeMax = (!isNaN(parseInt(data.rcEVBatteryRangeMax)) ? parseInt(data.rcEVBatteryRangeMax) : null);
    data.rcInsuranceGroupAvg = (!isNaN(parseInt(data.rcInsuranceGroupAvg)) ? parseInt(data.rcInsuranceGroupAvg) : null);
    data.rcInsuranceGroupMin = (!isNaN(parseInt(data.rcInsuranceGroupMin)) ? parseInt(data.rcInsuranceGroupMin) : null);
    data.rcInsuranceGroupMax = (!isNaN(parseInt(data.rcInsuranceGroupMax)) ? parseInt(data.rcInsuranceGroupMax) : null);
    data.rcAvgSMR1Year = (!isNaN(parseInt(data.rcAvgSMR1Year)) ? parseInt(data.rcAvgSMR1Year) : null);
    data.rcAvgSMR2Years = (!isNaN(parseInt(data.rcAvgSMR2Years)) ? parseInt(data.rcAvgSMR2Years) : null);
    data.rcAvgSMR3Years = (!isNaN(parseInt(data.rcAvgSMR3Years)) ? parseInt(data.rcAvgSMR3Years) : null);
    data.rcAvgSMR4Years = (!isNaN(parseInt(data.rcAvgSMR4Years)) ? parseInt(data.rcAvgSMR4Years) : null);
    data.rcAvgSMR5Years = (!isNaN(parseInt(data.rcAvgSMR5Years)) ? parseInt(data.rcAvgSMR5Years) : null);

// console.log(data);
    this.props.onUpdate(data);
    this.setState({ data: this.mapToViewModel(data) });
    this.props.onCancel(true);

  };

  handleCancelEditRunningCostsButtonClick() {
    this.props.onCancel(true);
  }

  handleDateChange = (date) => {
    let newData = { ...this.state.data };
    newData.date = date;
    this.setState({ data: newData });
  };

  render() {
    const { model } = this.props;

    return (
      <>
        <p>Enter whole numbers for all fields, with the exception of EV m/KWh which should be entered to 1 decimal point accuracy (e.g. 12.1).<br/>Zeros are significant, so if a running cost is not defined, leave it blank.</p>
        <form
          className='mb-4'
          onSubmit={this.handleSubmit}
          id={`rc` + model._id}
        >
        <Table
          bordered
          size="sm"
          style={{ marginBottom: 10 }}
        >
          <thead>
            <tr>
              <td>Parameter</td>
              <td>Value(s)</td>
            </tr>
          </thead>
          <tbody>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; CO2 average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselCO2Avg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; CO2 min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselCO2Min', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; CO2 max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselCO2Max', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; MPG average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselMPGAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; MPG min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselMPGMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Diesel &mdash; MPG max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcDieselMPGMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; CO2 average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolCO2Avg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; CO2 min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolCO2Min', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; CO2 max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolCO2Max', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; MPG average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolMPGAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; MPG min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolMPGMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Petrol &mdash; MPG max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPetrolMPGMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; CO2 average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridCO2Avg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; CO2 min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridCO2Min', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; CO2 max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridCO2Max', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; MPG average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridMPGAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; MPG min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridMPGMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Hybrid &mdash; MPG max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcHybridMPGMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; CO2 average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridCO2Avg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; CO2 min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridCO2Min', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; CO2 max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridCO2Max', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; battery range average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridBatteryRangeAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; battery range min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridBatteryRangeMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; battery range max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridBatteryRangeMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; MPG average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridMPGAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; MPG min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridMPGMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Plug-in hybrid &mdash; MPG max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcPlugInHybridMPGMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; m/KWh average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVMpKWhAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; m/KWh min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVMpKWhMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; m/KWh max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVMpKWhMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; battery range average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVBatteryRangeAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; battery range min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVBatteryRangeMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>EV &mdash; battery range max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcEVBatteryRangeMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Insurance group &mdash; average</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcInsuranceGroupAvg', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Insurance group &mdash; min</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcInsuranceGroupMin', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Insurance group &mdash; max</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcInsuranceGroupMax', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Average SMR for 1 year</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcAvgSMR1Year', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Average SMR for 2 years</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcAvgSMR2Years', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Average SMR for 3 years</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcAvgSMR3Years', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Average SMR for 4 years</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcAvgSMR4Years', '', '')}</td></tr>
            <tr><td style={{ verticalAlign:'middle' }}>Average SMR for 5 years</td><td style={{ paddingTop:'16px', paddingBottom:'0' }}>{this.renderInput('rcAvgSMR5Years', '', '')}</td></tr>
          </tbody>
        </Table>
        <hr/>
        <Row style={{ justifyContent: 'flex-end', marginLeft: 0, marginRight: 0 }}>
          <div className='form-group'>
            {this.renderButton(
              'Update',
              'btn-success btn-sm float-end'
            )}
            <Button
              size='sm'
              style={{ marginRight: 10 }}
              onClick={() => this.handleCancelEditRunningCostsButtonClick()}
            >
              Cancel
            </Button>
          </div>
        </Row>
        </form>
      </>
    );
  }
}

export default EditRunningCostsForm;
