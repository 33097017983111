import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import EditGreenNCAPForm from './editGreenNCAPForm';
import AddGreenNCAPForm from './addGreenNCAPForm';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_GREENNCAPS_FOR_MODEL,
  ADD_GREENNCAP,
  UPDATE_GREENNCAP,
  DELETE_GREENNCAP,
} from '../graphql/greenNCAPsMutations';

export default function VehicleDataGreenNCAPs(props) {
  const [showAddGreenNCAPForm, setShowAddGreenNCAPForm] = useState(false);
  const [greenncaps, setGreenncaps] = useState([]);
  const modelId = props.modelId;

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingGreenNCAPs,
    error: loadingGreenNCAPsError,
    data,
    refetch: refetchGreenNCAPs,
  } = useQuery(GET_GREENNCAPS_FOR_MODEL, {
    variables: { modelId: modelId },
  });

  const [
    addGreenNCAP,
    { loading: mutationAddLoading, error: mutationAddError },
  ] = useMutation(ADD_GREENNCAP);

  const [
    updateGreenNCAP,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useMutation(UPDATE_GREENNCAP);

  const [
    deleteGreenNCAP,
    { loading: mutationDeleteLoading, error: mutationDeleteError },
  ] = useMutation(DELETE_GREENNCAP);

  useEffect(() => {
    if (
      !loadingGreenNCAPs &&
      !mutationAddLoading &&
      !mutationUpdateLoading &&
      !mutationDeleteLoading &&
      data
    ) {
      setGreenncaps(data.greenncaps);
    }
  }, [
    loadingGreenNCAPs,
    mutationAddLoading,
    mutationUpdateLoading,
    mutationDeleteLoading,
    data,
  ]);

  if (loadingGreenNCAPsError) {
    return <p>Error: {loadingGreenNCAPsError}</p>;
  }

  if (mutationAddError) {
    return <p>Error: {mutationAddError}</p>;
  }
  if (mutationUpdateError) {
    return <p>Error: {mutationUpdateError}</p>;
  }
  if (mutationDeleteError) {
    return <p>Error: {mutationDeleteError}</p>;
  }

  function handleAddGreenNCAPButtonClick() {
    setShowAddGreenNCAPForm(true);
  }

  function handleCancelAddGreenNCAP() {
    setShowAddGreenNCAPForm(false);
  }

  async function handleAddGreenNCAP(data) {
    console.log('adding: ', data);

    const result = await addGreenNCAP({ variables: data });
    console.log('result: ', result);

    refetchGreenNCAPs({ modelId: modelId });
    setShowAddGreenNCAPForm(false);
    toast.success('GreenNCAP report successfully added');
  }

  async function handleUpdateGreenNCAP(data) {
    console.log('to save: ', data);

    const result = await updateGreenNCAP({
      variables: {
        greenncapId: data._id,
        cleanAirIndex: data.cleanAirIndex,
        date: data.date,
        energyEfficiencyIndex: data.energyEfficiencyIndex,
        greenhouseGasIndex: data.greenhouseGasIndex,
        isValid: data.isValid,
        modelId: data.modelId,
        modelReviewed: data.modelReviewed,
        starRating: data.starRating,
        url: data.url,
      },
    });
    console.log('result: ', result);

    refetchGreenNCAPs({ modelId: modelId });
    // setShowAddGreenNCAPForm(false);
    toast.success('GreenNCAP report successfully updated');
  }

  async function handleDeleteGreenNCAP(greenncapId) {
    const result = await deleteGreenNCAP({
      variables: { greenncapId: greenncapId },
    });
    console.log('result: ', result);

    refetchGreenNCAPs({ modelId: modelId });
    setShowAddGreenNCAPForm(false);
    toast.success('GreenNCAP report successfully deleted');
  }

  return (
    <>
      {loadingGreenNCAPs ? (
          <ClipLoader
          loading={true}
          cssOverride={cssoverride}
        />
      ) : (
        <div className='border px-4 pt-1 pb-4 mt-4 shadow'>
          <h4 className='mt-3 mb-4'>
            Green NCAP
            <Button
              className='float-end'
              color='primary'
              size='sm'
              onClick={() => handleAddGreenNCAPButtonClick(false)}
            >
              + Add Green NCAP report
            </Button>
          </h4>
          {showAddGreenNCAPForm && (
            <AddGreenNCAPForm
              modelId={modelId}
              onCancel={handleCancelAddGreenNCAP}
              onAdd={(data) => handleAddGreenNCAP(data)}
            />
          )}
          <hr />
          {!_.isEmpty(greenncaps) &&
            greenncaps.map((greenNCAPReport) => (
              <EditGreenNCAPForm
                key={greenNCAPReport._id}
                greenNCAPReport={greenNCAPReport}
                onUpdate={(data) => handleUpdateGreenNCAP(data)}
                onDelete={(greenncapId) => handleDeleteGreenNCAP(greenncapId)}
              />
            ))}
        </div>
      )}
    </>
  );
}
