import gql from "graphql-tag";

const GET_MODELS_FOR_MANUFACTURER = gql`
  query GetModelsForManufacturer($manufacturerId: ObjectId) {
    models(query: { manufacturerId: $manufacturerId }, sortBy: NAME_ASC) {
      _id
      name
      year
      manufacturerId
      prodStatus
      vehicleTypes
      vehicleTypeIds
      isActive
      isComplete
      isPublished
    }
  }
`;

const ADD_MODEL = gql`
  mutation AddModel(
    $name: String
    $year: Int
    $manufacturerId: ObjectId
    $prodStatus: String
    $vehicleTypeIds: [ObjectId]
    $vehicleTypes: [String]
    $isActive: Boolean
    $isComplete: Boolean
    $isPublished: Boolean
  ) {
    insertOneModel(
      data: {
        name: $name
        year: $year
        manufacturerId: $manufacturerId
        prodStatus: $prodStatus
        vehicleTypeIds: $vehicleTypeIds
        vehicleTypes: $vehicleTypes
        isActive: $isActive
        isComplete: $isComplete
        isPublished: $isPublished
      }
    ) {
      _id
      name
    }
  }
`;

const UPDATE_MODEL = gql`
  mutation UpdateModel(
    $modelId: ObjectId
    $name: String
    $year: Int
    $manufacturerId: ObjectId
    $prodStatus: String
    $vehicleTypeIds: [ObjectId]
    $vehicleTypes: [String]
    $isActive: Boolean
    $isComplete: Boolean
    $isPublished: Boolean
  ) {
    updatedModel: updateOneModel(
      query: { _id: $modelId }
      set: {
        name: $name
        year: $year
        manufacturerId: $manufacturerId
        prodStatus: $prodStatus
        vehicleTypeIds: $vehicleTypeIds
        vehicleTypes: $vehicleTypes
        isActive: $isActive
        isComplete: $isComplete
        isPublished: $isPublished
      }
    ) {
      _id
      name
    }
  }
`;

const DELETE_MODEL = gql`
  mutation DeleteModel($modelId: ObjectId!) {
    deletedModel: deleteOneModel(query: { _id: $modelId }) {
      _id
      name
    }
  }
`;

export { GET_MODELS_FOR_MANUFACTURER, ADD_MODEL, UPDATE_MODEL, DELETE_MODEL };
