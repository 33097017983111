import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useRealmApp } from '../RealmApp';

export default function NavBar() {
  const app = useRealmApp();
  const user = app.currentUser._profile.data.email;

  return (
    <div>
      <Navbar color='dark' dark expand='md'>
        <NavbarBrand tag={Link} to='/home'>
          PRISM
          {user && (
            <span
              style={{
                fontSize: '14px',
                color: '#a4a4a4',
                marginLeft: '10px',
              }}
            >
              v{process.env.REACT_APP_VERSION}
            </span>
          )}
        </NavbarBrand>
        <Nav className='ms-auto' navbar>
          {user && (
            <>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Logged in as {user}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to='/logout'>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </Nav>
      </Navbar>
    </div>
  );
}
