import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Button, Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';

class AddReviewForm extends Form {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      reviewSources: this.props.reviewSources,
      currentReviewSource: this.props.reviewSources[0].name,
      currentReviewSourceId: this.props.reviewSources[0]._id,
      data: {
        modelId: this.props.modelId,
        reviewSourceId: '',
        reviewSource: 'New Source',
        modelReviewed: '',
        url: '',
        hideURL: false,
        rating: '',
        date: new Date(),
        excerpt: '',
        author: '',
        inUK: '',
      },
      inUKOptions: [
        { _id: 'edit-yes', name: 'Yes' },
        { _id: 'edit-no', name: 'No' },
      ],
      errors: {},
    };
  }

  schema = {
    modelId: Joi.string(),
    reviewSourceId: Joi.string(),
    reviewSource: Joi.string(),
    url: Joi.string().min(1).max(255).required().label('Review url'),
    hideURL: Joi.boolean().optional().label('Hide URL'),
    modelReviewed: Joi.string()
      .min(0)
      .max(255)
      .allow('')
      .allow(null)
      .optional()
      .label('Model reviewed'),
    rating: Joi.number().precision(1).required().min(0).max(10).label('Rating'),
    date: Joi.date().required().label('Rating date'),
    excerpt: Joi.string().min(1).max(10000).label('Excerpt'),
    author: Joi.string().min(0).max(255).allow('').allow(null).optional().label('Author'),
    inUK: Joi.string().required().label('UK review?'),
  };

  async toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  doSubmit = async () => {
    let { data } = this.state;
    data.inUK === 'edit-yes' ? (data.inUK = true) : (data.inUK = false);

    delete data.reviewSource;
    data.rating = parseFloat(data.rating).toFixed(1);

    this.setState({
      data: {
        modelId: this.props.modelId,
        reviewSourceId: this.props.reviewSourceId,
        reviewSource: 'New Source',
        modelReviewed: '',
        url: '',
        hideURL: false,
        rating: '',
        date: new Date(),
        excerpt: '',
        author: '',
        inUK: '',
      },
    });
    this.props.onAdd(data);
    this.toggle();
  };

  handleReviewSourceChange = (event) => {
    const currentReviewSourceId = event.target.value;

    const currentReviewSource =
      event.target.options[event.target.selectedIndex].text;

    this.setState({ currentReviewSource, currentReviewSourceId });
  };

  handleReviewSourceSelected = () => {
    let newData = { ...this.state.data };

    newData.reviewSource = this.state.currentReviewSource;
    newData.reviewSourceId = this.state.currentReviewSourceId;

    this.setState({ data: newData, reviewSourceSelected: true });
  };

  handleCancelBtnClick = () => {
    this.props.onCancel();
  };

  handleDateChange = (date) => {
    let newData = { ...this.state.data };
    newData.date = date;
    this.setState({ data: newData });
  };

  handleHideURLChange = (hideURLSetting) => {
    let newData = { ...this.state.data };
    newData.hideURL = hideURLSetting.target.checked;
    this.setState({ data: newData });
  };

  render() {
    const { data, reviewSources, reviewSourceSelected } = this.state;

    if (reviewSourceSelected)
      return (
        <>
          <Card className='mt-3'>
            <CardHeader className='card-header-review'>
              <Button
                color=''
                onClick={this.toggle}
                style={{ textAlign: 'left' }}
                block
              >
                <h5>{data.reviewSource}</h5>
              </Button>
            </CardHeader>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <form
                  className='mb-4'
                  onSubmit={this.handleSubmit}
                  id={data._id}
                >
                  <div className='col'>
                    {this.renderInput('url', 'Review url')}
                    <div className='form-group'>
                      <label>
                        Hide URL:
                        <span style={{ paddingLeft: '10px' }} />
                          <input
                            name="hideURLCheckbox"
                            type="checkbox"
                            checked={data.hideURL}
                            onChange={this.handleHideURLChange}
                          />
                      </label>
                    </div>
                    {this.renderInput('modelReviewed', 'Model reviewed')}
                    {this.renderInput('rating', 'Rating')}
                    <div className='form-group'>
                      <label>Rating date</label>
                      <DatePicker
                        selected={data.date}
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        onChange={this.handleDateChange}
                      />
                    </div>
                    {this.renderTextarea('excerpt', 'Excerpt', '4')}
                    {this.renderInput('author', 'Author')}
                    {this.renderSelect(
                      'inUK',
                      'UK review?',
                      this.state.inUKOptions
                    )}
                    <div className='form-group'>
                      {this.renderButton(
                        'Save',
                        'btn-success btn-sm float-end mb-3'
                      )}
                      <Button
                        className='me-2 mb-3 float-end'
                        size='sm'
                        onClick={() => this.handleCancelBtnClick()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Collapse>
          </Card>
        </>
      );

    return (
      <>
        <div
          className='card card-body bg-white mt-4 shadow mx-auto'
          style={{ display: 'inline-block', minWidth: '100%' }}
        >
          <div className='col-12' style={{ display: 'inline-block' }}>
            <p>
              <strong>Select a Review Source</strong>
            </p>
            <div className='form-group mb-0'>
              <select
                className='form-control mb-3'
                onChange={this.handleReviewSourceChange}
              >
                {reviewSources.map((reviewSource) => (
                  <option key={reviewSource._id} value={reviewSource._id}>
                    {reviewSource.name}
                  </option>
                ))}
              </select>
            </div>

            <Button
              className='float-end'
              color='primary'
              size='sm'
              onClick={() => this.handleReviewSourceSelected()}
            >
              Go!
            </Button>
            <Button
              className='me-2 float-end'
              size='sm'
              onClick={() => this.handleCancelBtnClick()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default AddReviewForm;
