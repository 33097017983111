import gql from 'graphql-tag';

const GET_EURONCAPS_FOR_MODEL = gql`
  query GetEuroNCAPsForModel($modelId: ObjectId) {
    euroncaps(query: { modelId: $modelId }, sortBy: DATE_DESC) {
      _id
      adultOccupantRating
      childOccupantRating
      date
      isValid
      expiryDate
      modelId
      safetyAssistRating
      safetyTestVideoUrl
      starRating
      url
      vulnerabaleRoadUsersRating
    }
  }
`;

const ADD_EURONCAP = gql`
  mutation AddEuroNCAP(
    $adultOccupantRating: Int
    $childOccupantRating: Int
    $date: DateTime
    $isValid: Boolean
    $expiryDate: DateTime
    $modelId: ObjectId
    $safetyAssistRating: Int
    $safetyTestVideoUrl: String
    $starRating: Int
    $url: String
    $vulnerabaleRoadUsersRating: Int
  ) {
    insertOneEuroncap(
      data: {
        adultOccupantRating: $adultOccupantRating
        childOccupantRating: $childOccupantRating
        date: $date
        isValid: $isValid
        expiryDate: $expiryDate
        modelId: $modelId
        safetyAssistRating: $safetyAssistRating
        safetyTestVideoUrl: $safetyTestVideoUrl
        starRating: $starRating
        url: $url
        vulnerabaleRoadUsersRating: $vulnerabaleRoadUsersRating
      }
    ) {
      _id
      url
    }
  }
`;

const UPDATE_EURONCAP = gql`
  mutation UpdateEuroNCAP(
    $euroncapId: ObjectId
    $adultOccupantRating: Int
    $childOccupantRating: Int
    $date: DateTime
    $isValid: Boolean
    $expiryDate: DateTime
    $modelId: ObjectId
    $safetyAssistRating: Int
    $safetyTestVideoUrl: String
    $starRating: Int
    $url: String
    $vulnerabaleRoadUsersRating: Int
  ) {
    updatedEuroNCAP: updateOneEuroncap(
      query: { _id: $euroncapId }
      set: {
        adultOccupantRating: $adultOccupantRating
        childOccupantRating: $childOccupantRating
        date: $date
        isValid: $isValid
        expiryDate: $expiryDate
        modelId: $modelId
        safetyAssistRating: $safetyAssistRating
        safetyTestVideoUrl: $safetyTestVideoUrl
        starRating: $starRating
        url: $url
        vulnerabaleRoadUsersRating: $vulnerabaleRoadUsersRating
      }
    ) {
      _id
      url
    }
  }
`;

const DELETE_EURONCAP = gql`
  mutation DeleteEuroNCAP($euroncapId: ObjectId!) {
    deletedEuroNCAP: deleteOneEuroncap(query: { _id: $euroncapId }) {
      _id
      url
    }
  }
`;

export {
  GET_EURONCAPS_FOR_MODEL,
  ADD_EURONCAP,
  UPDATE_EURONCAP,
  DELETE_EURONCAP,
};
