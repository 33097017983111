import gql from "graphql-tag";

const GET_REVIEWSOURCES = gql`
  query GetReviewSources {
    reviewsources(sortBy: NAME_ASC) {
      _id
      name
      isActive
      displayReview
      displayReviewScores
      logo
    }
  }
`;
const ADD_REVIEWSOURCE = gql`
  mutation AddReviewsource(
    $name: String!
    $logo: String
    $isActive: Boolean
    $displayReview: Boolean
    $displayReviewScores: Boolean
  ) {
    insertOneReviewsource(
      data: {
        name: $name
        logo: $logo
        isActive: $isActive
        displayReview: $displayReview
        displayReviewScores: $displayReviewScores
      }
    ) {
      _id
      name
    }
  }
`;

const UPDATE_REVIEWSOURCE = gql`
  mutation UpdateReviewSource(
    $reviewsourceId: ObjectId
    $name: String
    $logo: String
    $isActive: Boolean
    $displayReview: Boolean
    $displayReviewScores: Boolean
  ) {
    updatedReviewSource: updateOneReviewsource(
      query: { _id: $reviewsourceId }
      set: {
        name: $name
        logo: $logo
        isActive: $isActive
        displayReview: $displayReview
        displayReviewScores: $displayReviewScores
      }
    ) {
      _id
      name
    }
  }
`;

const DELETE_REVIEWSOURCE = gql`
  mutation DeleteReviewSource($reviewsourceId: ObjectId!) {
    deletedReviewSource: deleteOneReviewsource(
      query: { _id: $reviewsourceId }
    ) {
      _id
      name
    }
  }
`;

export {
  GET_REVIEWSOURCES,
  ADD_REVIEWSOURCE,
  UPDATE_REVIEWSOURCE,
  DELETE_REVIEWSOURCE,
};
