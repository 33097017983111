import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Textarea = ({ name, label, rows, error, ...rest }) => {
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <textarea
        {...rest}
        name={name}
        id={uuidv4()}
        rows={rows}
        className='form-control'
      />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Textarea;
