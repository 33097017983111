import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Parser } from 'json2csv';

export default function ReportReviewSourceTables() {
  const [reviewSources, setReviewSources] = useState([]);
  const [assemblingReviews, setAssemblingReviews] = useState(true);

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingReviewSources,
    error: errorReviewSources,
    data: dataReviewSources,
  } = useQuery(gql`
    query {
      reviewsources(query: { isActive: true }, limit: 200) {
        _id
        name
        allMin
        allMax
        allCount
        currentMin
        currentMax
        currentCount
      }
    }
  `);

  function assembleReviewSourceTablesforExport(reviewSources) {
    console.log('Start assembling review source tables...');

    var content = 'data:text/csv;charset=utf-8,';

    const reviewSourcesClone = JSON.parse(JSON.stringify(reviewSources));

    const fields = [
      { label: 'Review Source', value: 'name' },
      { label: 'Review Source ID', value: '_id' },
      { label: 'Min rating', value: 'allMin' },
      { label: 'Max rating', value: 'allMax' },
      { label: 'Rating count', value: 'allCount' },
      { label: 'Min rating (current only)', value: 'currentMin' },
      { label: 'Max rating (current only)', value: 'currentMax' },
      { label: 'Rating count (current only)', value: 'currentCount' },
    ];


    const opts = { fields };
    const parser = new Parser(opts);
    const csv = parser.parse(reviewSourcesClone);
    content += csv;

    console.log('ready to download');
    window.open(encodeURI(content));

    setAssemblingReviews(false);
  }

  useEffect(() => {
    if (loadingReviewSources === false && dataReviewSources) {
      setReviewSources(dataReviewSources.reviewsources);
      assembleReviewSourceTablesforExport(dataReviewSources.reviewsources);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingReviewSources,
    dataReviewSources,
  ]);

  if (errorReviewSources) {
    return <div>encountered an error: {errorReviewSources}</div>;
  }

  return (
    <>
      <p color='primary'>Running All reviews report...</p>
      {loadingReviewSources ? (
        <>
          <p>
            Step 1 of 2: Pulling in active manufacturers, models and review
            sources...
          </p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 1 of 2: Active review sources loaded.
          </p>
          <p>Pulled {reviewSources.length} active review sources</p>
        </>
      )}
      {assemblingReviews ? (
        <>
          <p>Step 2 of 2: Assembling review source tables...</p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 2 of 2: All review source table data ready — file downloading...
          </p>
        </>
      )}
    </>
  );
}