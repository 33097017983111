import React, { Component } from 'react';
import Table from './common/table';
import { Button } from 'reactstrap';

class ReviewSourcesTable extends Component {
  handleEditBtnClick(reviewSourceId) {
    this.props.onClickToEdit(reviewSourceId);
  }

  columns = [
    {
      path: 'name',
      label: 'Review source name',
      class: 'align-middle',
      content: (reviewSource) => (
        <Button
          color='link'
          className={reviewSource.isActive ? 'text-success' : ''}
          onClick={() => this.handleEditBtnClick(reviewSource._id)}
          disabled={!this.props.inEditMode}
        >
          {reviewSource.name}
        </Button>
      ),
    },
    {
      path: 'image',
      label: 'Logo',
      class: 'text-center align-middle',
      content: (reviewSource) => (
        <>
          <img
            width='50'
            height='50'
            src= {`../../review-source-logos/` + reviewSource.logo}
            alt='Logo'
          />
        </>
      ),
    },
    {
      path: 'displayReview',
      label: 'Show Review?',
      class: 'text-center align-middle',
      content: (reviewSource) => (
        <span
          className={
            this.props.inEditMode
              ? reviewSource.displayReview
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {reviewSource.displayReview ? 'Yes' : 'No'}
        </span>
      ),
    },
    {
      path: 'displayReviewScores',
      label: 'Show Review Score?',
      class: 'text-center align-middle',
      content: (reviewSource) => (
        <span
          className={
            this.props.inEditMode
              ? reviewSource.displayReviewScores
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {reviewSource.displayReviewScores ? 'Yes' : 'No'}
        </span>
      ),
    },
    {
      path: 'isActive',
      label: 'Status',
      class: 'text-center align-middle',
      content: (reviewSource) => (
        <span
          className={
            this.props.inEditMode
              ? reviewSource.isActive
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {reviewSource.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
  ];

  render() {
    const { reviewSources, onSort, sortColumn } = this.props;

    if (reviewSources.length === 0)
      return (
        <>
          <div className='row mt-3'>
            <div className='col'>
              <p>There are no review sources in the database</p>{' '}
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className='row mt-3'>
          <div className='col'>
            <p>
              Showing {reviewSources.length} review source
              {reviewSources.length > 1 ? 's' : ''} in the database
            </p>
            <Table
              columns={this.columns}
              data={reviewSources}
              sortColumn={sortColumn}
              onSort={onSort}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ReviewSourcesTable;
