import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Card, Button, CardDeck, CardBody } from 'reactstrap';

class AddModelForm extends Form {
  state = {
    data: {
      name: '',
      year: '',
      vehicleTypeIds: [],
      prodStatus: '',
      isActive: '',
    },
    manufacturer: this.props.manufacturer,
    vehicleTypes: [],
    prodStatusOptions: [
      { _id: 'current', name: 'Current' },
      { _id: 'discontinued', name: 'Discontinued' },
    ],
    statusOptions: [
      { _id: 'new-active', name: 'Active' },
      { _id: 'new-inactive', name: 'Inactive' },
    ],
    errors: {},
  };

  schema = {
    name: Joi.string().required().min(1).max(255).label('Name'),
    year: Joi.number().integer().required().min(2000).max(2100).label('Year'),
    vehicleTypeIds: Joi.array().items(Joi.string()).required().label('Types'),
    prodStatus: Joi.string().required().label('Production Status'),
    isActive: Joi.string().required().label('Status'),
  };

  async populateVehicleTypes() {
    const vehTypes = this.props.vehicleTypes;
    this.setState({ vehTypes });
  }

  async componentDidMount() {
    await this.populateVehicleTypes();
  }

  doSubmit = async () => {
    let { data } = this.state;

    data.isActive === 'new-active'
      ? (data.isActive = true)
      : (data.isActive = false);

    data.manufacturerId = this.props.manufacturerId;

    this.setState({
      data: {
        name: '',
        year: '',
        vehicleTypeIds: [],
        prodStatus: '',
        isActive: '',
      },
    });

    this.props.onNewModelAdded(data);
  };

  handleAddOrCancelButtons = (setting) => {
    this.props.onButtonClick(setting);
  };

  render() {
    const {
      manufacturer,
      showAddModelForm,
      buttonTitle,
      vehicleTypes,
    } = this.props;

    if (showAddModelForm)
      return (
        <>
          <div className='row mt-4'>
            <div className='col'>
              <Button
                className='float-end'
                color='primary'
                size='sm'
                onClick={() => this.handleAddOrCancelButtons(false)}
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className='row mt-4'>
          <div className='col'>
            <CardDeck>
              <Card className='shadow'>
                <CardBody>
                  <h4>Adding new {manufacturer} model</h4>
                  <form className='mt-4' onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                      <div className='col'>
                        {this.renderInput('name', 'Model name')}
                      </div>
                      <div className='col'>
                        {this.renderSelectMultiple(
                          'vehicleTypeIds',
                          'Types',
                          vehicleTypes
                        )}
                      </div>
                      <div className='col'>
                        {this.renderInput('year', 'Model year')}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'prodStatus',
                          'Prod. Status',
                          this.state.prodStatusOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'isActive',
                          'Status',
                          this.state.statusOptions
                        )}
                      </div>
                    </div>
                    {this.renderButton('Add', 'btn-success btn-sm float-end')}
                    <Button
                      className='me-2 float-end'
                      size='sm'
                      onClick={() => this.handleAddOrCancelButtons(true)}
                    >
                      Cancel
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </CardDeck>
          </div>
        </div>
      </>
    );
  }
}

export default AddModelForm;
