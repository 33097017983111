import { Link } from 'react-router-dom';
import { useRealmApp } from '../RealmApp';
import {
  Card,
  CardDeck,
  Button,
  CardHeader,
  CardText,
  CardBody,
} from 'reactstrap';

export default function Home() {
  const app = useRealmApp();
  const userEmail = app.currentUser._profile.data.email;
  let userLevel = 'dataentry';

  // console.log(app.currentUser);

  if (
    userEmail === 'frank@thinkanewmedia.com' ||
    userEmail === 'stuart@inl.co.uk' ||
    userEmail === 'sean@thecarexpert.co.uk'
  ) {
    userLevel = 'admin';
  }

  return (
    <>
      <h1>Welcome</h1>
      <h4>
        To get started entering reviews for a vehicle, choose Manage Vehicle
        Data below.
      </h4>
      <CardDeck className='home-card-deck'>
        {(userLevel === 'dataentry' || userLevel === 'admin') && (
          <>
            <Card className='shadow home-card'>
              <CardHeader>Manage Vehicle Data</CardHeader>
              <CardBody>
                <CardText>
                  You can create, view and update vehicle review data in here.
                </CardText>

                <Button
                  style={{
                    width: '100%',
                    backgroundColor: '#17a2b8',
                  }}
                  tag={Link}
                  to='/manage-vehicle-data'
                >
                  Go
                </Button>
              </CardBody>
            </Card>
          </>
        )}
        {userLevel === 'admin' && (
          <>
            <Card className='shadow home-card card-admin'>
              <CardHeader>Configure Models</CardHeader>
              <CardBody>
                <CardText>
                  You can configure vehicle models for each manufacturer in
                  here.
                </CardText>
                <Button
                  style={{
                    width: '100%',
                  }}
                  tag={Link}
                  to='/configure-models'
                >
                  Go
                </Button>
              </CardBody>
            </Card>
            <Card className='shadow home-card card-admin'>
              <CardHeader>Configure Review Sources</CardHeader>
              <CardBody>
                <CardText>
                  You can configure the review sources available in here.
                </CardText>
                <Button
                  style={{
                    width: '100%',
                  }}
                  tag={Link}
                  to='/configure-review-sources'
                >
                  Go
                </Button>
              </CardBody>
            </Card>
            <Card className='shadow home-card card-admin'>
              <CardHeader>View Reports</CardHeader>
              <CardBody>
                <CardText>
                  You can view reports of the vehicle data in here.
                </CardText>
                <Button
                  style={{
                    width: '100%',
                  }}
                  tag={Link}
                  to='/view-reports'
                >
                  Go
                </Button>
              </CardBody>
            </Card>
          </>
        )}
      </CardDeck>
      {/* <CardDeck>
        {user.isSuperAdmin && (
          <>
            <Card className='shadow home-card card-admin'>
              <CardHeader>Manage WP Plugin Sites</CardHeader>
              <CardBody>
                <CardText>
                  You can review and update content used on a WordPress site in
                  here.
                </CardText>
                <Button
                  style={{
                    width: '100%'
                  }}
                  tag={Link}
                  to='/manage-plugin-sites'
                >
                  Go
                </Button>
              </CardBody>
            </Card>
            <Card />
            <Card />
          </>
        )}
      </CardDeck> */}
    </>
  );
}
