import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import EditEuroNCAPForm from './editEuroNCAPForm';
import AddEuroNCAPForm from './addEuroNCAPForm';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_EURONCAPS_FOR_MODEL,
  ADD_EURONCAP,
  UPDATE_EURONCAP,
  DELETE_EURONCAP,
} from '../graphql/euroNCAPsMutations.js';

export default function VehicleDataEuroNCAPs(props) {
  const [showAddEuroNCAPForm, setShowAddEuroNCAPForm] = useState(false);
  const [euroncaps, setEuroncaps] = useState([]);

  const modelId = props.modelId;

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingEuroNCAPs,
    error: loadingEuroNCAPsError,
    data,
    refetch: refetchEuroNCAPs,
  } = useQuery(GET_EURONCAPS_FOR_MODEL, {
    variables: { modelId: modelId },
  });

  const [
    addEuroNCAP,
    { loading: mutationAddLoading, error: mutationAddError },
  ] = useMutation(ADD_EURONCAP);

  const [
    updateEuroNCAP,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useMutation(UPDATE_EURONCAP);

  const [
    deleteEuroNCAP,
    { loading: mutationDeleteLoading, error: mutationDeleteError },
  ] = useMutation(DELETE_EURONCAP);

  useEffect(() => {
    if (
      !loadingEuroNCAPs &&
      !mutationAddLoading &&
      !mutationUpdateLoading &&
      !mutationDeleteLoading &&
      data
    ) {
      setEuroncaps(data.euroncaps);
    }
  }, [
    loadingEuroNCAPs,
    mutationAddLoading,
    mutationUpdateLoading,
    mutationDeleteLoading,
    data,
  ]);

  if (loadingEuroNCAPsError) {
    return <p>Error: {loadingEuroNCAPsError}</p>;
  }

  if (mutationAddError) {
    return <p>Error: {mutationAddError}</p>;
  }
  if (mutationUpdateError) {
    return <p>Error: {mutationUpdateError}</p>;
  }
  if (mutationDeleteError) {
    return <p>Error: {mutationDeleteError}</p>;
  }

  function handleAddEuroNCAPButtonClick() {
    setShowAddEuroNCAPForm(true);
  }

  function handleCancelAddEuroNCAP() {
    setShowAddEuroNCAPForm(false);
  }

  async function handleAddEuroNCAP(data) {
    console.log('adding: ', data);

    const result = await addEuroNCAP({ variables: data });
    console.log('result: ', result);

    refetchEuroNCAPs({ modelId: modelId });
    setShowAddEuroNCAPForm(false);
    toast.success('EuroNCAP report successfully added');
  }

  async function handleUpdateEuroNCAP(data) {
    console.log('to save: ', data);

    const result = await updateEuroNCAP({
      variables: {
        euroncapId: data._id,
        adultOccupantRating: data.adultOccupantRating,
        childOccupantRating: data.childOccupantRating,
        date: data.date,
        isValid: data.isValid,
        expiryDate: data.expiryDate,
        modelId: data.modelId,
        safetyAssistRating: data.safetyAssistRating,
        safetyTestVideoUrl: data.safetyTestVideoUrl,
        starRating: data.starRating,
        url: data.url,
        vulnerabaleRoadUsersRating: data.vulnerabaleRoadUsersRating,
      },
    });
    console.log('result: ', result);

    refetchEuroNCAPs({ modelId: modelId });
    // setShowAddEuroNCAPForm(false);
    toast.success('EuroNCAP report successfully updated');
  }

  async function handleDeleteEuroNCAP(euroncapId) {
    const result = await deleteEuroNCAP({
      variables: { euroncapId: euroncapId },
    });
    console.log('result: ', result);

    refetchEuroNCAPs({ modelId: modelId });
    setShowAddEuroNCAPForm(false);
    toast.success('EuroNCAP report successfully deleted');
  }

  return (
    <>
      {loadingEuroNCAPs ? (
        <ClipLoader
        loading={true}
        cssOverride={cssoverride}
      />
      ) : (
        <div className='border px-4 pt-1 pb-4 mt-4 shadow'>
          <h4 className='mt-3 mb-4'>
            Euro NCAP
            <Button
              className='float-end'
              color='primary'
              size='sm'
              onClick={() => handleAddEuroNCAPButtonClick(false)}
            >
              + Add Euro NCAP report
            </Button>
          </h4>
          {showAddEuroNCAPForm && (
            <AddEuroNCAPForm
              modelId={modelId}
              onCancel={handleCancelAddEuroNCAP}
              onAdd={(data) => handleAddEuroNCAP(data)}
            />
          )}
          <hr />
          {!_.isEmpty(euroncaps) &&
            euroncaps.map((euroNCAPReport) => (
              <EditEuroNCAPForm
                key={euroNCAPReport._id}
                euroNCAPReport={euroNCAPReport}
                onUpdate={(data) => handleUpdateEuroNCAP(data)}
                onDelete={(euroncapId) => handleDeleteEuroNCAP(euroncapId)}
              />
            ))}
        </div>
      )}
    </>
  );
}
