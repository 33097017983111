import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';
import Pagination from './common/pagination';
import ModelsTable from './modelsTable';
import AddModelForm from './addModelForm';
import EditModelForm from './editModelForm';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  GET_MODELS_FOR_MANUFACTURER,
  ADD_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL,
} from '../graphql/modelsMutations';

export default function ConfigureModels() {
  const pageSize = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [showAddModelForm, setShowAddModelForm] = useState(true);
  const [currentManufacturer, setCurrentManufacturer] = useState('');
  const [currentManufacturerId, setCurrentManufacturerId] = useState(
    '5c45a2f92e86592f90baf93c'
  );
  const [modelIdEditing, setModelIdEditing] = useState('');
  const [sortColumn, setSortColumn] = useState({ path: 'name', order: 'asc' });

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const [
    addModel,
    { loading: mutationAddLoading, error: mutationAddError },
  ] = useMutation(ADD_MODEL);

  const [
    updateModel,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useMutation(UPDATE_MODEL);

  const [
    deleteModel,
    { loading: mutationDeleteLoading, error: mutationDeleteError },
  ] = useMutation(DELETE_MODEL);

  const {
    loading: loadingManufacturers,
    error: errorManufacturers,
    data: dataManufacturers,
  } = useQuery(gql`
    query {
      manufacturers(query: { isPublished_ne: false }, sortBy: NAME_ASC) {
        _id
        name
        isPublished
      }
      vehicletypes(sortBy: NAME_ASC) {
        _id
        name
      }
    }
  `);

  const {
    loading: loadingModels,
    error: errorModels,
    data: dataModels,
    refetch: refetchModels,
  } = useQuery(GET_MODELS_FOR_MANUFACTURER, {
    variables: { manufacturerId: '5c45a2f92e86592f90baf93c' },
  });

  useEffect(() => {
    if (loadingManufacturers === false && dataManufacturers) {
      setManufacturers(dataManufacturers.manufacturers);
      setVehicleTypes(dataManufacturers.vehicletypes);
      setCurrentManufacturer(dataManufacturers.manufacturers[0].name);
      setCurrentManufacturerId(dataManufacturers.manufacturers[0]._id);
    }
  }, [loadingManufacturers, dataManufacturers]);

  useEffect(() => {
    if (loadingModels === false && dataModels) {
      setModels(dataModels.models);
    }
  }, [loadingModels, dataModels]);

  if (errorManufacturers) {
    return <p>Error: {errorManufacturers}</p>;
  }

  if (errorModels) {
    return <p>Error: {errorModels}</p>;
  }

  if (mutationAddError) {
    return <p>Error: {mutationAddError}</p>;
  }
  if (mutationUpdateError) {
    return <p>Error: {mutationUpdateError}</p>;
  }
  if (mutationDeleteError) {
    return <p>Error: {mutationDeleteError}</p>;
  }

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handleSort(sortColumn) {
    setSortColumn(sortColumn);
  }

  function handleAddButtonClick(result) {
    setShowAddModelForm(result);
  }

  function handleEditCancel() {
    setModelIdEditing('');
    // setShowAddModelForm(false);
  }

  function handleClickToEdit(modelId) {
    setModelIdEditing(modelId);
  }

  async function handleAddModel(data) {
    console.log('save data: ', data);

    data.vehicleTypes = [];

    data.vehicleTypeIds.forEach((typeId) => {
      data.vehicleTypes.push(
        vehicleTypes[
          vehicleTypes
            .map(function (type) {
              return type._id;
            })
            .indexOf(typeId)
        ].name
      );
    });

    const result = await addModel({
      variables: {
        name: data.name,
        year: data.year,
        manufacturerId: data.manufacturerId,
        prodStatus: data.prodStatus,
        vehicleTypeIds: data.vehicleTypeIds,
        vehicleTypes: data.vehicleTypes,
        isActive: data.isActive,
        isComplete: false,
        isPublished: false,
      },
    });
    console.log('result: ', result);

    refetchModels({ manufacturerId: currentManufacturerId });
    setShowAddModelForm(true);
    toast.success('Model successfully added');
  }

  async function handleSaveModel(data) {
    data.vehicleTypes = [];

    data.vehicleTypeIds.forEach((typeId) => {
      data.vehicleTypes.push(
        vehicleTypes[
          vehicleTypes
            .map(function (type) {
              return type._id;
            })
            .indexOf(typeId)
        ].name
      );
    });

    console.log('save data: ', data);

    const result = await updateModel({
      variables: {
        modelId: data._id,
        name: data.name,
        year: data.year,
        manufacturerId: data.manufacturerId,
        prodStatus: data.prodStatus,
        vehicleTypeIds: data.vehicleTypeIds,
        vehicleTypes: data.vehicleTypes,
        isActive: data.isActive,
        isComplete: data.isComplete,
        isPublished: data.isPublished,
      },
    });

    console.log('result: ', result);

    refetchModels({ manufacturerId: currentManufacturerId });
    setModelIdEditing('');
    setShowAddModelForm(true);
    toast.success('Model successfully updated');
  }

  function handleManufacturerChange(event) {
    if (!loadingModels) {
      refetchModels({ manufacturerId: String(event.target.value) });
    }

    let newManufacturer = _.find(manufacturers, {
      _id: String(event.target.value),
    });
    setCurrentManufacturerId(String(event.target.value));
    setCurrentManufacturer(newManufacturer.name);
  }

  async function handleDeleteModel(modelId) {
    console.log('deleting: ', modelId);

    const result = await deleteModel({
      variables: { modelId: modelId },
    });

    console.log('result: ', result);

    refetchModels({ manufacturerId: currentManufacturerId });
    setShowAddModelForm(true);
    setModelIdEditing('');
    toast.success('Model successfully deleted');
  }

  const sortedModels = _.orderBy(models, [sortColumn.path], [sortColumn.order]);
  const totalCount = sortedModels.length;

  return (
    <>
      <p>
        Select a manufacturer below to view the models currently in the
        database. Once selected, you will be able to edit existing models for
        that manufacturer as well as create new ones. {` `}
        <span className='text-danger'>
          <em>
            Deleting a model will delete ALL associated data. There is no undo,
            so please take care when choosing to delete.
          </em>
        </span>
      </p>
      <div
        className='card card-body bg-white mt-4 shadow mx-auto'
        style={{ minWidth: 400, maxWidth: 'fit-content' }}
      >
        <div className='col-auto'>
          <p>
            <strong>Select a Manufacturer</strong>
          </p>
          {loadingManufacturers ? (
              <ClipLoader
                loading={true}
                cssOverride={cssoverride}
              />
          ) : (
            <div className='form-group mb-0'>
              <select
                className='form-control mb-3'
                onChange={handleManufacturerChange}
                disabled={
                  modelIdEditing === '' && showAddModelForm ? false : true
                }
              >
                {manufacturers.map((manufacturer) => (
                  <option key={manufacturer._id} value={manufacturer._id}>
                    {manufacturer.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {modelIdEditing === '' ? (
        <AddModelForm
          manufacturer={currentManufacturer}
          manufacturerId={currentManufacturerId}
          vehicleTypes={vehicleTypes}
          showAddModelForm={showAddModelForm}
          buttonTitle={`+ Add a New ` + currentManufacturer}
          onButtonClick={handleAddButtonClick}
          onNewModelAdded={(data) => handleAddModel(data)}
          // onNewModelAdded={handleNewModelAdded}
        />
      ) : (
        <EditModelForm
          manufacturer={currentManufacturer}
          manufacturerId={currentManufacturerId}
          modelId={modelIdEditing}
          model={models.filter((model) => model._id === modelIdEditing)[0]}
          vehicleTypes={vehicleTypes}
          onCancel={handleEditCancel}
          onSave={(modelId) => handleSaveModel(modelId)}
          onDelete={(modelId) => handleDeleteModel(modelId)}
        />
      )}
      {loadingModels ||
      mutationAddLoading ||
      mutationUpdateLoading ||
      mutationDeleteLoading ? (
        <ClipLoader
        loading={true}
        cssOverride={cssoverride}
      />
      ) : (
        <ModelsTable
          key={currentManufacturer}
          models={sortedModels}
          sortColumn={sortColumn}
          onSort={handleSort}
          inEditMode={modelIdEditing === '' && showAddModelForm === true}
          onClickToEdit={handleClickToEdit}
        />
      )}
      <Pagination
        onPageChange={handlePageChange}
        currentPage={currentPage}
        itemsCount={totalCount}
        pageSize={pageSize}
      />
    </>
  );
}
