import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <h4>
      Not Found - take me <Link to='/home'>Home</Link>
    </h4>
  );
};

export default NotFound;
