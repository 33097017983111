import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import { Row, Button, Table } from 'reactstrap';
import EditRunningCostsForm from './editRunningCostsForm';
import gql from 'graphql-tag';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
// import { useQuery, useMutation } from '@apollo/client';
import { useMutation } from '@apollo/client';

export default function VehicleDataRunningCosts(props) {

  const [showRunningCostsEditor, setShowRunningCostsEditor] = useState(false);

  // const [modelRunningCosts, setModelRunningCosts] = useState([]);

  const model = props.model;
  const loadingModel = props.loadingModel;

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  function handleEditRunningCostsButtonClick() {
    setShowRunningCostsEditor(true);
  }

  function handleCancelEditRunningCostsButtonClick() {
    setShowRunningCostsEditor(false);
  }

//   const {
//     loading: loadingRunningCosts,
//     error: loadingRunningCostsError,
//     data,
//     refetch: refetchRunningCosts,
//   } = useQuery(gql`
//   query ($modelId: ObjectId) {
//     model(query: { _id: $modelId }) {
//       _id
//       rcDieselCO2Avg
//       rcDieselCO2Min
//       rcDieselCO2Max
//       rcDieselMPGAvg
//       rcDieselMPGMin
//       rcDieselMPGMax
//       rcPetrolCO2Avg
//       rcPetrolCO2Min
//       rcPetrolCO2Max
//       rcPetrolMPGAvg
//       rcPetrolMPGMin
//       rcPetrolMPGMax
//       rcHybridCO2Avg
//       rcHybridCO2Min
//       rcHybridCO2Max
//       rcHybridMPGAvg
//       rcHybridMPGMin
//       rcHybridMPGMax
//       rcPlugInHybridCO2Avg
//       rcPlugInHybridCO2Min
//       rcPlugInHybridCO2Max
//       rcPlugInHybridBatteryRangeAvg
//       rcPlugInHybridBatteryRangeMin
//       rcPlugInHybridBatteryRangeMax
//       rcPlugInHybridMPGAvg
//       rcPlugInHybridMPGMin
//       rcPlugInHybridMPGMax
//       rcEVMpKWhAvg
//       rcEVMpKWhMin
//       rcEVMpKWhMax
//       rcEVBatteryRangeAvg
//       rcEVBatteryRangeMin
//       rcEVBatteryRangeMax
//       rcInsuranceGroupAvg
//       rcInsuranceGroupMin
//       rcInsuranceGroupMax
//       rcAvgSMR1Year
//       rcAvgSMR2Years
//       rcAvgSMR3Years
//       rcAvgSMR4Years
//       rcAvgSMR5Years
//     }
//   }
// `,
// {
//   variables: {
//     modelId: String(props.modelId),
//   },
// }
// );


  const [
    updateRunningCosts,
    { loading: mutationUpdateRunningCosts, error: mutationRunningCostsStatusError },
  ] = useMutation(gql`
    mutation UpdateRunningCosts(
      $modelId: ObjectId
      $rcDieselCO2Avg: Int
      $rcDieselCO2Min: Int
      $rcDieselCO2Max: Int
      $rcDieselMPGAvg: Int
      $rcDieselMPGMin: Int
      $rcDieselMPGMax: Int
      $rcPetrolCO2Avg: Int
      $rcPetrolCO2Min: Int
      $rcPetrolCO2Max: Int
      $rcPetrolMPGAvg: Int
      $rcPetrolMPGMin: Int
      $rcPetrolMPGMax: Int
      $rcHybridCO2Avg: Int
      $rcHybridCO2Min: Int
      $rcHybridCO2Max: Int
      $rcHybridMPGAvg: Int
      $rcHybridMPGMin: Int
      $rcHybridMPGMax: Int
      $rcPlugInHybridCO2Avg: Int
      $rcPlugInHybridCO2Min: Int
      $rcPlugInHybridCO2Max: Int
      $rcPlugInHybridBatteryRangeAvg: Int
      $rcPlugInHybridBatteryRangeMin: Int
      $rcPlugInHybridBatteryRangeMax: Int
      $rcPlugInHybridMPGAvg: Int
      $rcPlugInHybridMPGMin: Int
      $rcPlugInHybridMPGMax: Int
      $rcEVMpKWhAvg: Float
      $rcEVMpKWhMin: Float
      $rcEVMpKWhMax: Float
      $rcEVBatteryRangeAvg: Int
      $rcEVBatteryRangeMin: Int
      $rcEVBatteryRangeMax: Int
      $rcInsuranceGroupAvg: Int
      $rcInsuranceGroupMin: Int
      $rcInsuranceGroupMax: Int
      $rcAvgSMR1Year: Int
      $rcAvgSMR2Years: Int
      $rcAvgSMR3Years: Int
      $rcAvgSMR4Years: Int
      $rcAvgSMR5Years: Int
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { 
          rcDieselCO2Avg: $rcDieselCO2Avg
          rcDieselCO2Min: $rcDieselCO2Min
          rcDieselCO2Max: $rcDieselCO2Max
          rcDieselMPGAvg: $rcDieselMPGAvg
          rcDieselMPGMin: $rcDieselMPGMin
          rcDieselMPGMax: $rcDieselMPGMax
          rcPetrolCO2Avg: $rcPetrolCO2Avg
          rcPetrolCO2Min: $rcPetrolCO2Min
          rcPetrolCO2Max: $rcPetrolCO2Max
          rcPetrolMPGAvg: $rcPetrolMPGAvg
          rcPetrolMPGMin: $rcPetrolMPGMin
          rcPetrolMPGMax: $rcPetrolMPGMax
          rcHybridCO2Avg: $rcHybridCO2Avg
          rcHybridCO2Min: $rcHybridCO2Min
          rcHybridCO2Max: $rcHybridCO2Max
          rcHybridMPGAvg: $rcHybridMPGAvg
          rcHybridMPGMin: $rcHybridMPGMin
          rcHybridMPGMax: $rcHybridMPGMax
          rcPlugInHybridCO2Avg: $rcPlugInHybridCO2Avg
          rcPlugInHybridCO2Min: $rcPlugInHybridCO2Min
          rcPlugInHybridCO2Max: $rcPlugInHybridCO2Max
          rcPlugInHybridBatteryRangeAvg: $rcPlugInHybridBatteryRangeAvg
          rcPlugInHybridBatteryRangeMin: $rcPlugInHybridBatteryRangeMin
          rcPlugInHybridBatteryRangeMax: $rcPlugInHybridBatteryRangeMax
          rcPlugInHybridMPGAvg: $rcPlugInHybridMPGAvg
          rcPlugInHybridMPGMin: $rcPlugInHybridMPGMin
          rcPlugInHybridMPGMax: $rcPlugInHybridMPGMax
          rcEVMpKWhAvg: $rcEVMpKWhAvg
          rcEVMpKWhMin: $rcEVMpKWhMin
          rcEVMpKWhMax: $rcEVMpKWhMax
          rcEVBatteryRangeAvg: $rcEVBatteryRangeAvg
          rcEVBatteryRangeMin: $rcEVBatteryRangeMin
          rcEVBatteryRangeMax: $rcEVBatteryRangeMax
          rcInsuranceGroupAvg: $rcInsuranceGroupAvg
          rcInsuranceGroupMin: $rcInsuranceGroupMin
          rcInsuranceGroupMax: $rcInsuranceGroupMax
          rcAvgSMR1Year: $rcAvgSMR1Year
          rcAvgSMR2Years: $rcAvgSMR2Years
          rcAvgSMR3Years: $rcAvgSMR3Years
          rcAvgSMR4Years: $rcAvgSMR4Years
          rcAvgSMR5Years: $rcAvgSMR5Years
        }
      ) {
        _id
        name
      }
    }
  `);


  // useEffect(() => {
  //   if (
  //     !loadingRunningCosts &&
  //     !mutationUpdateRunningCosts &&
  //     data
  //   ) {
  //     // setModelRunningCosts(data);

  //     // console.log(data);


  //   }
  // }, [
  //   loadingRunningCosts,
  //   mutationUpdateRunningCosts,
  //   data,
  // ]);

  // if (loadingRunningCostsError) {
  //   return <p>Error: {loadingRunningCostsError}</p>;
  // }

  if (mutationRunningCostsStatusError) {
    return <p>Error: {mutationRunningCostsStatusError}</p>;
  }


  async function handleUpdateRunningCosts(data) {
    console.log('to save: ', data);

    const result = await updateRunningCosts({
      variables: {
        modelId: data.modelId,
        rcDieselCO2Avg: data.rcDieselCO2Avg,
        rcDieselCO2Min: data.rcDieselCO2Min,
        rcDieselCO2Max: data.rcDieselCO2Max,
        rcDieselMPGAvg: data.rcDieselMPGAvg,
        rcDieselMPGMin: data.rcDieselMPGMin,
        rcDieselMPGMax: data.rcDieselMPGMax,
        rcPetrolCO2Avg: data.rcPetrolCO2Avg,
        rcPetrolCO2Min: data.rcPetrolCO2Min,
        rcPetrolCO2Max: data.rcPetrolCO2Max,
        rcPetrolMPGAvg: data.rcPetrolMPGAvg,
        rcPetrolMPGMin: data.rcPetrolMPGMin,
        rcPetrolMPGMax: data.rcPetrolMPGMax,
        rcHybridCO2Avg: data.rcHybridCO2Avg,
        rcHybridCO2Min: data.rcHybridCO2Min,
        rcHybridCO2Max: data.rcHybridCO2Max,
        rcHybridMPGAvg: data.rcHybridMPGAvg,
        rcHybridMPGMin: data.rcHybridMPGMin,
        rcHybridMPGMax: data.rcHybridMPGMax,
        rcPlugInHybridCO2Avg: data.rcPlugInHybridCO2Avg,
        rcPlugInHybridCO2Min: data.rcPlugInHybridCO2Min,
        rcPlugInHybridCO2Max: data.rcPlugInHybridCO2Max,
        rcPlugInHybridBatteryRangeAvg: data.rcPlugInHybridBatteryRangeAvg,
        rcPlugInHybridBatteryRangeMin: data.rcPlugInHybridBatteryRangeMin,
        rcPlugInHybridBatteryRangeMax: data.rcPlugInHybridBatteryRangeMax,
        rcPlugInHybridMPGAvg: data.rcPlugInHybridMPGAvg,
        rcPlugInHybridMPGMin: data.rcPlugInHybridMPGMin,
        rcPlugInHybridMPGMax: data.rcPlugInHybridMPGMax,
        rcEVMpKWhAvg: data.rcEVMpKWhAvg,
        rcEVMpKWhMin: data.rcEVMpKWhMin,
        rcEVMpKWhMax: data.rcEVMpKWhMax,
        rcEVBatteryRangeAvg: data.rcEVBatteryRangeAvg,
        rcEVBatteryRangeMin: data.rcEVBatteryRangeMin,
        rcEVBatteryRangeMax: data.rcEVBatteryRangeMax,
        rcInsuranceGroupAvg: data.rcInsuranceGroupAvg,
        rcInsuranceGroupMin: data.rcInsuranceGroupMin,
        rcInsuranceGroupMax: data.rcInsuranceGroupMax,
        rcAvgSMR1Year: data.rcAvgSMR1Year,
        rcAvgSMR2Years: data.rcAvgSMR2Years,
        rcAvgSMR3Years: data.rcAvgSMR3Years,
        rcAvgSMR4Years: data.rcAvgSMR4Years,
        rcAvgSMR5Years: data.rcAvgSMR5Years
      },
    });
    console.log('result: ', result);
    props.onChange();
    // refetchRunningCosts({ modelId: data.modelId });
    toast.success('Running Costs successfully updated');
  }

  function getCO2Score(value) {

    const co2Ranking_B = 136;
    const co2Ranking_C = 157;
    const co2Ranking_D = 193;
    const co2Ranking_E = 394;

    if(value < co2Ranking_B) {
      return 'A';
    }
    if(value < co2Ranking_C) {
      return 'B';
    }
    if(value < co2Ranking_D) {
      return 'C';
    }
    if(value < co2Ranking_E) {
      return 'D';
    }
    if(value > co2Ranking_D) {
      return 'E';
    }

    return '-';
  }

  function getMPGScore(value) {
    const mpgRanking_B = 58;
    const mpgRanking_C = 49;
    const mpgRanking_D = 42;
    const mpgRanking_E = 33;

    if(value > mpgRanking_B) {
      return 'A';
    }
    if(value > mpgRanking_C) {
      return 'B';
    }
    if(value > mpgRanking_D) {
      return 'C';
    }
    if(value > mpgRanking_E) {
      return 'D';
    }
    if(value <= mpgRanking_E) {
      return 'E';
    }

    return '-';
  }

  function getBatteryRangeScore(value) {
    const batteryRangeRanking_B = 283;
    const batteryRangeRanking_C = 186;
    const batteryRangeRanking_D = 50;
    const batteryRangeRanking_E = 36;

    if(value > batteryRangeRanking_B) {
      return 'A';
    }
    if(value > batteryRangeRanking_C) {
      return 'B';
    }
    if(value > batteryRangeRanking_D) {
      return 'C';
    }
    if(value > batteryRangeRanking_E) {
      return 'D';
    }
    if(value <= batteryRangeRanking_E) {
      return 'E';
    }

    return '-';
  }

  function getElectricalEfficiencyScore(value) {
    const electricalEfficiencyRanking_B = 4.4;
    const electricalEfficiencyRanking_C = 4.1;
    const electricalEfficiencyRanking_D = 3.7;
    const electricalEfficiencyRanking_E = 3.4;

    if(value > electricalEfficiencyRanking_B) {
      return 'A';
    }
    if(value > electricalEfficiencyRanking_C) {
      return 'B';
    }
    if(value > electricalEfficiencyRanking_D) {
      return 'C';
    }
    if(value > electricalEfficiencyRanking_E) {
      return 'D';
    }
    if(value <= electricalEfficiencyRanking_E) {
      return 'E';
    }

    return '-';
  }

  function getInsuranceGroupScore(value) {
    const insuranceGroupRanking_B = 23;
    const insuranceGroupRanking_C = 29;
    const insuranceGroupRanking_D = 41;
    const insuranceGroupRanking_E = 49;
    const insuranceGroupRanking_F = 50;

    if(value < insuranceGroupRanking_B) {
      return 'A';
    }
    if(value < insuranceGroupRanking_C) {
      return 'B';
    }
    if(value < insuranceGroupRanking_D) {
      return 'C';
    }
    if(value < insuranceGroupRanking_E) {
      return 'D';
    }
    if(value < insuranceGroupRanking_F) {
      return 'E';
    }
    if(value === insuranceGroupRanking_F) {
      return 'F';
    }

    return '-';
  }

  function getYear1SMRScore(value) {
    const year1SMRScore_B = 199;
    const year1SMRScore_C = 262;
    const year1SMRScore_D = 410;
    const year1SMRScore_E = 738;

    if(value < year1SMRScore_B) {
      return 'A';
    }
    if(value < year1SMRScore_C) {
      return 'B';
    }
    if(value < year1SMRScore_D) {
      return 'C';
    }
    if(value < year1SMRScore_E) {
      return 'D';
    }
    if(value >= year1SMRScore_E) {
      return 'E';
    }

    return '-';
  }
  function getYear2SMRScore(value) {
    const year2SMRScore_B = 505;
    const year2SMRScore_C = 640;
    const year2SMRScore_D = 966;
    const year2SMRScore_E = 1842;

    if(value < year2SMRScore_B) {
      return 'A';
    }
    if(value < year2SMRScore_C) {
      return 'B';
    }
    if(value < year2SMRScore_D) {
      return 'C';
    }
    if(value < year2SMRScore_E) {
      return 'D';
    }
    if(value >= year2SMRScore_E) {
      return 'E';
    }

    return '-';
  }
  function getYear3SMRScore(value) {
    const year3SMRScore_B = 823;
    const year3SMRScore_C = 1043;
    const year3SMRScore_D = 1545;
    const year3SMRScore_E = 3244;

    if(value < year3SMRScore_B) {
      return 'A';
    }
    if(value < year3SMRScore_C) {
      return 'B';
    }
    if(value < year3SMRScore_D) {
      return 'C';
    }
    if(value < year3SMRScore_E) {
      return 'D';
    }
    if(value >= year3SMRScore_E) {
      return 'E';
    }

    return '-';
  }
  function getYear4SMRScore(value) {
    const year4SMRScore_B = 1028;
    const year4SMRScore_C = 1250;
    const year4SMRScore_D = 1810;
    const year4SMRScore_E = 4090;

    if(value < year4SMRScore_B) {
      return 'A';
    }
    if(value < year4SMRScore_C) {
      return 'B';
    }
    if(value < year4SMRScore_D) {
      return 'C';
    }
    if(value < year4SMRScore_E) {
      return 'D';
    }
    if(value >= year4SMRScore_E) {
      return 'E';
    }

    return '-';
  }

  function getYear5SMRScore(value) {
    const year5SMRScore_B = 1387;
    const year5SMRScore_C = 1670;
    const year5SMRScore_D = 2404;
    const year5SMRScore_E = 5435;

    if(value < year5SMRScore_B) {
      return 'A';
    }
    if(value < year5SMRScore_C) {
      return 'B';
    }
    if(value < year5SMRScore_D) {
      return 'C';
    }
    if(value < year5SMRScore_E) {
      return 'D';
    }
    if(value >= year5SMRScore_E) {
      return 'E';
    }

    return '-';
  }

  function getOverallSMRScore(value) {
    
    
    // const overallSMRScore_A = 3399;
    const overallSMRScore_B = 3959;
    const overallSMRScore_C = 4842;
    const overallSMRScore_D = 7097;
    const overallSMRScore_E = 15025;

    if(value < overallSMRScore_B) {
      return 'A';
    }
    if(value < overallSMRScore_C) {
      return 'B';
    }
    if(value < overallSMRScore_D) {
      return 'C';
    }
    if(value < overallSMRScore_E) {
      return 'D';
    }
    if(value >= overallSMRScore_E) {
      return 'E';
    }

    return '-';
  }

  return (
    <div className='border px-4 pt-1 pb-4 mt-4 shadow'>
      <h4 className='mt-3 mb-4'>Running Costs from CVD</h4>
      <hr/>
      {loadingModel || mutationUpdateRunningCosts ?
        <ClipLoader
        loading={true}
        cssOverride={cssoverride}
      /> : ''}
      {!showRunningCostsEditor ? (
        <>
          <Table
            bordered
            size="sm"
            style={{ marginBottom: 10 }}
          >
            <thead>
              <tr>
                <td>Parameter</td>
                <td>Value(s)</td>
                <td>Score</td>
              </tr>
            </thead>
            <tbody>
              {model.rcDieselCO2Avg !== null && (<tr><td>Diesel &mdash; CO2 average</td><td>{model.rcDieselCO2Avg} g/km</td><td>{getCO2Score(model.rcDieselCO2Avg)}</td></tr>)}
              {model.rcDieselCO2Min !== null && model.rcDieselCO2Max !== null && (<tr><td>Diesel &mdash; CO2 variation</td><td>{model.rcDieselCO2Min} - {model.rcDieselCO2Max} g/km</td><td>{getCO2Score(model.rcDieselCO2Min)} - {getCO2Score(model.rcDieselCO2Max)}</td></tr>)}
              {model.rcDieselMPGAvg !== null && (<tr><td>Diesel &mdash; MPG average</td><td>{model.rcDieselMPGAvg} mpg</td><td>{getMPGScore(model.rcDieselMPGAvg)}</td></tr>)}
              {model.rcDieselMPGMin !== null && model.rcDieselMPGMax !== null && (<tr><td>Diesel &mdash; MPG variation</td><td>{model.rcDieselMPGMin} - {model.rcDieselMPGMax} mpg</td><td>{getMPGScore(model.rcDieselMPGMax)} - {getMPGScore(model.rcDieselMPGMin)}</td></tr>)}
              {model.rcPetrolCO2Avg !== null && (<tr><td>Petrol &mdash; CO2 average</td><td>{model.rcPetrolCO2Avg} g/km</td><td>{getCO2Score(model.rcPetrolCO2Avg)}</td></tr>)}
              {model.rcPetrolCO2Min !== null && model.rcPetrolCO2Max !== null && (<tr><td>Petrol &mdash; CO2 variation</td><td>{model.rcPetrolCO2Min} - {model.rcPetrolCO2Max} g/km</td><td>{getCO2Score(model.rcPetrolCO2Min)} - {getCO2Score(model.rcPetrolCO2Max)}</td></tr>)}
              {model.rcPetrolMPGAvg !== null && (<tr><td>Petrol &mdash; MPG average</td><td>{model.rcPetrolMPGAvg} mpg</td><td>{getMPGScore(model.rcPetrolMPGAvg)}</td></tr>)}
              {model.rcPetrolMPGMin !== null && model.rcPetrolMPGMax !== null && (<tr><td>Petrol &mdash; MPG variation</td><td>{model.rcPetrolMPGMin} - {model.rcPetrolMPGMax} mpg</td><td>{getMPGScore(model.rcPetrolMPGMax)} - {getMPGScore(model.rcPetrolMPGMin)}</td></tr>)}
              {model.rcHybridCO2Avg !== null && (<tr><td>Hybrid &mdash; CO2 average</td><td>{model.rcHybridCO2Avg} g/km</td><td>{getCO2Score(model.rcHybridCO2Avg)}</td></tr>)}
              {model.rcHybridCO2Min !== null && model.rcHybridCO2Max !== null && (<tr><td>Hybrid &mdash; CO2 variation</td><td>{model.rcHybridCO2Min} - {model.rcHybridCO2Max} g/km</td><td>{getCO2Score(model.rcHybridCO2Min)} - {getCO2Score(model.rcHybridCO2Max)}</td></tr>)}
              {model.rcHybridMPGAvg !== null && (<tr><td>Hybrid &mdash; MPG average</td><td>{model.rcHybridMPGAvg} mpg</td><td>{getMPGScore(model.rcHybridMPGAvg)}</td></tr>)}
              {model.rcHybridMPGMin !== null && model.rcHybridMPGMax !== null && (<tr><td>Hybrid &mdash; MPG variation</td><td>{model.rcHybridMPGMin} - {model.rcHybridMPGMax} mpg</td><td>{getMPGScore(model.rcHybridMPGMax)} - {getMPGScore(model.rcHybridMPGMin)}</td></tr>)}
              {model.rcPlugInHybridCO2Avg !== null && (<tr><td>Plug-in hybrid &mdash; CO2 average</td><td>{model.rcPlugInHybridCO2Avg} g/km</td><td>{getCO2Score(model.rcPlugInHybridCO2Avg)}</td></tr>)}
              {model.rcPlugInHybridCO2Min !== null && model.rcPlugInHybridCO2Max !== null && (<tr><td>Plug-in hybrid &mdash; CO2 variation</td><td>{model.rcPlugInHybridCO2Min} - {model.rcPlugInHybridCO2Max} g/km</td><td>{getCO2Score(model.rcPlugInHybridCO2Min)} - {getCO2Score(model.rcPlugInHybridCO2Max)}</td></tr>)}
              {model.rcPlugInHybridBatteryRangeAvg !== null && (<tr><td>Plug-in hybrid &mdash; battery range average</td><td>{model.rcPlugInHybridBatteryRangeAvg} miles</td><td>{getBatteryRangeScore(model.rcPlugInHybridBatteryRangeAvg)}</td></tr>)}
              {model.rcPlugInHybridBatteryRangeMin !== null && model.rcPlugInHybridBatteryRangeMax !== null && (<tr><td>Plug-in hybrid &mdash; battery range range</td><td>{model.rcPlugInHybridBatteryRangeMin} - {model.rcPlugInHybridBatteryRangeMax} miles</td><td>{getBatteryRangeScore(model.rcPlugInHybridBatteryRangeMax)} - {getBatteryRangeScore(model.rcPlugInHybridBatteryRangeMin)}</td></tr>)}
              {model.rcPlugInHybridMPGAvg !== null && (<tr><td>Plug-in hybrid &mdash; MPG average</td><td>{model.rcPlugInHybridMPGAvg} mpg</td><td>{getMPGScore(model.rcPlugInHybridMPGAvg)}</td></tr>)}
              {model.rcPlugInHybridMPGMin !== null && model.rcPlugInHybridMPGMax !== null && (<tr><td>Plug-in hybrid &mdash; MPG variation</td><td>{model.rcPlugInHybridMPGMin} - {model.rcPlugInHybridMPGMax} mpg</td><td>{getMPGScore(model.rcPlugInHybridMPGMax)} - {getMPGScore(model.rcPlugInHybridMPGMin)}</td></tr>)}
              {model.rcEVMpKWhAvg !== null && (<tr><td>EV &mdash; m/KWh average</td><td>{model.rcEVMpKWhAvg} m/KWh</td><td>{getElectricalEfficiencyScore(model.rcEVMpKWhAvg)}</td></tr>)}
              {model.rcEVMpKWhMin !== null && model.rcEVMpKWhMax !== null && (<tr><td>EV &mdash; m/KWh variation</td><td>{model.rcEVMpKWhMin} - {model.rcEVMpKWhMax} m/KWh</td><td>{getElectricalEfficiencyScore(model.rcEVMpKWhMax)} - {getElectricalEfficiencyScore(model.rcEVMpKWhMin)}</td></tr>)}
              {model.rcEVBatteryRangeAvg !== null && (<tr><td>EV &mdash; battery range average</td><td>{model.rcEVBatteryRangeAvg} miles</td><td>{getBatteryRangeScore(model.rcEVBatteryRangeAvg)}</td></tr>)}
              {model.rcEVBatteryRangeMin !== null && model.rcEVBatteryRangeMax !== null && (<tr><td>EV &mdash; battery range variation</td><td>{model.rcEVBatteryRangeMin} - {model.rcEVBatteryRangeMax} miles</td><td>{getBatteryRangeScore(model.rcEVBatteryRangeMax)} - {getBatteryRangeScore(model.rcEVBatteryRangeMin)}</td></tr>)}
              {model.rcInsuranceGroupAvg !== null && (<tr><td>Insurance group &mdash; average</td><td>{model.rcInsuranceGroupAvg}</td><td>{getInsuranceGroupScore(model.rcInsuranceGroupAvg)}</td></tr>)}
              {model.rcInsuranceGroupMin !== null && model.rcInsuranceGroupMax !== null && (<tr><td>Insurance group &mdash; variation</td><td>{model.rcInsuranceGroupMin} - {model.rcInsuranceGroupMax}</td><td>{getInsuranceGroupScore(model.rcInsuranceGroupMin)} - {getInsuranceGroupScore(model.rcInsuranceGroupMax)}</td></tr>)}
              {model.rcAvgSMR1Year !== null && (<tr><td>Average SMR for 1 year</td><td>£{model.rcAvgSMR1Year}</td><td>{getYear1SMRScore(model.rcAvgSMR1Year)}</td></tr>)}
              {model.rcAvgSMR2Years !== null && (<tr><td>Average SMR for 2 years</td><td>£{model.rcAvgSMR2Years}</td><td>{getYear2SMRScore(model.rcAvgSMR2Years)}</td></tr>)}
              {model.rcAvgSMR3Years !== null && (<tr><td>Average SMR for 3 years</td><td>£{model.rcAvgSMR3Years}</td><td>{getYear3SMRScore(model.rcAvgSMR3Years)}</td></tr>)}
              {model.rcAvgSMR4Years !== null && (<tr><td>Average SMR for 4 years</td><td>£{model.rcAvgSMR4Years}</td><td>{getYear4SMRScore(model.rcAvgSMR4Years)}</td></tr>)}
              {model.rcAvgSMR5Years !== null && (<tr><td>Average SMR for 5 years</td><td>£{model.rcAvgSMR5Years}</td><td>{getYear5SMRScore(model.rcAvgSMR5Years)}</td></tr>)}
              {model.rcAvgSMR1Year !== null && (<tr><td>SMR overall</td><td>£{model.rcAvgSMR1Year+model.rcAvgSMR2Years+model.rcAvgSMR3Years+model.rcAvgSMR4Years+model.rcAvgSMR5Years}</td><td>{getOverallSMRScore(model.rcAvgSMR1Year+model.rcAvgSMR2Years+model.rcAvgSMR3Years+model.rcAvgSMR4Years+model.rcAvgSMR5Years)}</td></tr>)}
            </tbody>

          </Table>
          <hr/>
          <Row style={{ justifyContent: 'flex-end', marginLeft: 0, marginRight: 0 }}>
            <Button
              color='primary'
              size='sm'
              className='float-end'
              onClick={() => handleEditRunningCostsButtonClick()}
            >
              Edit running costs
            </Button>
          </Row>
        </>
      ) : (
        <>
        <EditRunningCostsForm
          model={model}
          onUpdate={(data) => handleUpdateRunningCosts(data)}
          onCancel={() => handleCancelEditRunningCostsButtonClick()}
        />
        </>
      )}
    </div>
    
  );
}
