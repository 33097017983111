import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Card, Button, CardDeck, CardBody } from 'reactstrap';

class EditModelForm extends Form {
  state = {
    showDeleteConfirm: false,
    data: {
      name: '',
      year: '',
      vehicleTypeIds: [],
      prodStatus: '',
      isActive: '',
    },
    manufacturer: this.props.manufacturer,
    vehicleTypes: this.props.vehicleTypes,
    prodStatusOptions: [
      { _id: 'current', name: 'Current' },
      { _id: 'discontinued', name: 'Discontinued' },
    ],
    statusOptions: [
      { _id: 'edit-active', name: 'Active' },
      { _id: 'edit-inactive', name: 'Inactive' },
    ],
    completeOptions: [
      { _id: 'edit-incomplete', name: 'Incomplete' },
      { _id: 'edit-complete', name: 'Complete' },
    ],
    publishedOptions: [
      { _id: 'edit-published', name: 'Yes' },
      { _id: 'edit-notpublished', name: 'No' },
    ],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().min(1).max(255).label('Name'),
    year: Joi.number().integer().required().min(2000).max(2100).label('Year'),
    vehicleTypeIds: Joi.array().items(Joi.string()).required().label('Types'),
    prodStatus: Joi.string().required().label('Production Status'),
    isActive: Joi.string().required().label('Status'),
    isComplete: Joi.string().required().label('Complete'),
    isPublished: Joi.string().required().label('Published'),
  };

  async populateModel() {
    const thisModel = this.props.model;
    this.setState({ data: this.mapToViewModel(thisModel) });
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.populateModel();
  }

  mapToViewModel(model) {
    return {
      _id: model._id,
      name: model.name,
      year: model.year,
      vehicleTypeIds: model.vehicleTypeIds,
      prodStatus: model.prodStatus === null ? 'current' : model.prodStatus,
      isActive: model.isActive ? 'edit-active' : 'edit-inactive',
      isComplete: model.isComplete ? 'edit-complete' : 'edit-incomplete',
      isPublished: model.isPublished ? 'edit-published' : 'edit-notpublished',
    };
  }

  doSubmit = async () => {
    let { data } = this.state;

    data.isActive === 'edit-active'
      ? (data.isActive = true)
      : (data.isActive = false);

    data.isComplete === 'edit-complete'
      ? (data.isComplete = true)
      : (data.isComplete = false);

    data.isPublished === 'edit-published'
      ? (data.isPublished = true)
      : (data.isPublished = false);

    data.manufacturerId = this.props.manufacturerId;

    this.props.onSave(data);
  };

  handleEditCancelBtnClick = () => {
    this.props.onCancel();
  };

  handleDeleteBtnClick = () => {
    this.setState({ showDeleteConfirm: true });
  };

  handleDeleteConfirmBtnYesClick = async () => {
    this.props.onDelete(this.props.modelId);
  };

  handleDeleteConfirmBtnNoClick = () => {
    this.setState({ showDeleteConfirm: false });
  };

  render() {
    const {
      data,
      manufacturer,
      showDeleteConfirm,
      vehicleTypes,
      prodStatusOptions,
      statusOptions,
      completeOptions,
      publishedOptions,
    } = this.state;
    return (
      <>
        <div className='row mt-4'>
          <div className='col'>
            <CardDeck>
              <Card className='shadow'>
                <CardBody>
                  <h4>
                    Editing {manufacturer} <strong>{data.name}</strong>
                  </h4>
                  <form className='mt-4' onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                      <div className='col'>
                        {this.renderInput('name', 'Model Name')}
                      </div>
                      <div className='col'>
                        {this.renderSelectMultiple(
                          'vehicleTypeIds',
                          'Types',
                          vehicleTypes
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'prodStatus',
                          'Prod. Status',
                          prodStatusOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderInput('year', 'Model Year')}
                      </div>
                      <div className='col'>
                        {this.renderSelect('isActive', 'Status', statusOptions)}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'isComplete',
                          'Complete',
                          completeOptions
                        )}
                      </div>
                      <div className='col'>
                        {this.renderSelect(
                          'isPublished',
                          'Published',
                          publishedOptions
                        )}
                      </div>
                    </div>
                    {!showDeleteConfirm && (
                      <>
                        {this.renderButton(
                          'Update',
                          'btn-success btn-sm float-end'
                        )}
                        <Button
                          className='me-2 float-end'
                          size='sm'
                          onClick={() => this.handleEditCancelBtnClick()}
                        >
                          Cancel
                        </Button>
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          color='danger'
                          onClick={() => this.handleDeleteBtnClick()}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {showDeleteConfirm && (
                      <>
                        <Button
                          className='mb-3 float-end'
                          size='sm'
                          outline
                          color='secondary'
                          onClick={() => this.handleDeleteConfirmBtnNoClick()}
                        >
                          No
                        </Button>
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          outline
                          color='danger'
                          onClick={() => this.handleDeleteConfirmBtnYesClick()}
                        >
                          Yes
                        </Button>
                        <span className='text-danger me-2 mb-3 float-end'>
                          Are you sure you want to delete this and all
                          associated data?
                        </span>
                      </>
                    )}
                  </form>
                </CardBody>
              </Card>
            </CardDeck>
          </div>
        </div>
      </>
    );
  }
}

export default EditModelForm;
