import { useRealmApp } from '../RealmApp';

export default async function Logout() {
  const app = useRealmApp();

  await app.logOut();
  window.location = '/';

  return null;
}
