import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import ClipLoader from 'react-spinners/ClipLoader';
import { Container, ButtonGroup, Button, Row, Col } from 'reactstrap';
import VehicleDataEuroNCAPs from './vehicleDataEuroNCAPs';
import VehicleDataGreenNCAPs from './vehicleDataGreenNCAPs';
import VehicleDataReviews from './vehicleDataReviews';
import VehicleDataRunningCosts from './vehicleDataRunningCosts';

export default function VehicleData(props) {
  const [model, setModel] = useState({});
  const [reviews, setReviews] = useState([]);
  const [reviewsources, setReviewSources] = useState([]);
  const [aggregateMediaRating, setAggregateMediaRating] = useState(0);
  const [newCarMediaRating, setNewCarMediaRating] = useState(0);
  const [usedCarMediaRating, setUsedCarMediaRating] = useState(0);
  const [dataEntryNoteValue, setDataEntryNoteValue] = useState('');
  const [showDataEntryNoteEditor, setShowDataEntryNoteEditor] = useState(false);
  const [modelSummaryValue, setModelSummaryValue] = useState('');
  const [showModelSummaryEditor, setShowModelSummaryEditor] = useState(false);

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const [currentMotorEasyMake, setCurrentMotorEasyMake] = useState('Select a brand');
  const [currentMotorEasyModelData, setCurrentMotorEasyModelData] = useState([]);
  const [currentMotorEasyModelIndex, setCurrentMotorEasyModelIndex] = useState(0);
  // modelReliability is the data stored in the model database
  const [modelReliability, setModelReliability] = useState({});

  // const [currentModelFV, setCurrentModelFV] = useState({});
  // modelFutureValue is the data stored in the model database
  // const [modelFutureValue, setModelFutureValue] = useState({});

  // const [numFVIds, setNumFVIds] = useState(0);
  // const [loadingFVData, setLoadingFVData] = useState(true);

  async function getMotorEasyModelData(theMake) {
    const response = await fetch('https://services.motangel.co.uk/Lookup.svc/get-reliability-index-data/json', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Cookie': 'ASP.NET_SessionId=amgcyvo4xn2bsw0izlqpcnli'
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        "APIUserName": "carexpert_t7dL1SrGXtWy1@hHsK2L",
        "APIPassword": "vo3q6n16Nw8BBL4plZ0GOoRUUxHVtm",
        "Make": theMake,
      })
    });

    return response.json();
    // return response.json(); // parses JSON response into native JavaScript objects
  }

  async function handleMotorEasyMakeChange(event) {
    setCurrentMotorEasyMake(event.target.value);
    setCurrentMotorEasyModelIndex(0);
    setCurrentMotorEasyModelData([]);

    // fetch Model data...
    getMotorEasyModelData(event.target.value)
    .then((data) => {
      // console.log(data);
      setCurrentMotorEasyModelData(data.Items)
    });
  }

  async function handleMotorEasyModelChange(event) {
    setCurrentMotorEasyModelIndex(event.target.value);
  }

  const motorEasyMakes = [
    "Select a brand",
    "ABARTH",
    "AIWAYS",
    "ALFA ROMEO",
    "ALPINE",
    "ASTON MARTIN",
    "AUDI",
    "BMW",
    "BYD",
    "BENTLEY",
    "BUGATTI",
    "CADILLAC",
    "CATERHAM",
    "CHEVROLET",
    "CHRYSLER",
    "CITROEN",
    "CUPRA",
    "DACIA",
    "DAEWOO",
    "DODGE",
    "DS",
    "FERRARI",
    "FIAT",
    "FISKER",
    "FORD",
    "GENESIS",
    "HONDA",
    "HYUNDAI",
    "INFINITI",
    "ISUZU",
    "ISUZU TRUCKS",
    "IVECO",
    "JAGUAR",
    "JEEP",
    "KIA",
    "LAMBORGHINI",
    "LAND ROVER",
    "LEXUS",
    "LOTUS",
    "MASERATI",
    "MAZDA",
    "MCLAREN",
    "MERCEDES",
    "MG",
    "MINI",
    "MITSUBISHI",
    "MORGAN",
    "NISSAN",
    "PEUGEOT",
    "POLESTAR",
    "PORSCHE",
    "RENAULT",
    "ROLLS ROYCE",
    "ROVER",
    "SAAB",
    "SEAT",
    "SKODA",
    "SMART",
    "SSANGYONG",
    "SUBARU",
    "SUZUKI",
    "TESLA",
    "TOYOTA",
    "VAUXHALL",
    "VOLKSWAGEN",
    "VOLVO",
  ];

// Brego future value...

// async function handleFVInputChange(event) {

//   // console.log(event.target.name);
//   // console.log(event.target.value);
//   let tempCurrentModelFV = JSON.parse(JSON.stringify(currentModelFV));
//   tempCurrentModelFV[`${event.target.name}`] = event.target.value;
//   setCurrentModelFV(tempCurrentModelFV);
// }

// async function handleFVSubmitandRun() {

//   // Get and set number of valid FV IDs
//   let numFVIds = 0;

// // console.log('current...');
// // console.log(currentModelFV);

//   let tempCurrentModelFV = JSON.parse(JSON.stringify(currentModelFV));

// console.log('temp...');
// console.log(tempCurrentModelFV);

//   for(let i=1; i<=10; i++) {

//     if(currentModelFV[`vehicleId${i}`].length === 36) {
//       numFVIds++;
//       getBregoFVData(currentModelFV[`vehicleId${i}`])
//       .then((data) => {

//         tempCurrentModelFV[`vehicleId${i}Data`] = data.items;
//         setCurrentModelFV(tempCurrentModelFV);

//       });
//     }
//   }

//   setLoadingFVData(false);
//   setNumFVIds(numFVIds);
 

// }

// https://api.brego.io/v1/vehicles/derivativeId/bb17feb8-c63c-4e97-909d-7928999fffa5/value?countryCode=gb&year=2022&mileage=100&futureMonthlyMileage=667&dates=2022-11-02T00%3A00%3A00Z&dates=2023-11-02T00%3A00%3A00Z&dates=2024-11-02T00%3A00%3A00Z&dates=2025-11-02T00%3A00%3A00Z&dates=2026-11-02T00%3A00%3A00Z&dates=2027-11-02T00%3A00%3A00Z

// valuations instead of value
// valuations.items
// previous owners set to 1 if not working


// Create todays date and next 5 years dates to feed into URL
// var d = new Date();
// const currentYear = d.getFullYear();

// var DateArrayforBrego = [];
// DateArrayforBrego[0] = d.getFullYear()+'-'+(d.getMonth()+1).toString().padStart(2, '0')+'-'+d.getDate().toString().padStart(2, '0');

// for (let i = 1; i < 6; i++) {
//   DateArrayforBrego[i] = (d.getFullYear()+i)+'-'+(d.getMonth()+1).toString().padStart(2, '0')+'-'+d.getDate().toString().padStart(2, '0');
// }

// async function getBregoFVData(vID) {

//   // const response = await fetch('https://api.brego.io/v1/vehicles/derivativeId/1cd2d761-1525-4f2f-808a-7839cf7edb63/value?countryCode=gb&dates=2022-10-25T12%3A00%3A00.000Z&dates=2023-10-25T12%3A00%3A00.000Z&dates=2024-10-25T12%3A00%3A00.000Z&dates=2025-10-25T12%3A00%3A00.000Z&dates=2026-10-25T12%3A00%3A00.000Z&dates=2027-10-25T12%3A00%3A00.000Z', {
//   // const response = await fetch('https://api.brego.io/v1/vehicles/derivativeId/' + vID + '/value?countryCode=gb&year=2022&mileage=100&futureMonthlyMileage=667&previousOwners=1&registrationDate=2022-11-14T00%3A00%3A00Z&dates=2022-11-14T00%3A00%3A00Z&dates=2023-11-14T00%3A00%3A00Z&dates=2024-11-14T00%3A00%3A00Z&dates=2025-11-14T00%3A00%3A00Z&dates=2026-11-14T00%3A00%3A00Z&dates=2027-11-14T00%3A00%3A00Z', {

//   const response = await fetch('https://api.brego.io/v1/vehicles/derivativeId/'+vID+'/valuations?countryCode=gb&year='+currentYear+'&mileage=100&futureMonthlyMileage=667&previousOwners=1&registrationDate='+DateArrayforBrego[0]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[0]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[1]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[2]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[3]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[4]+'T00%3A00%3A00Z&dates='+DateArrayforBrego[5]+'T00%3A00%3A00Z', {
//     method: 'GET', // *GET, POST, PUT, DELETE, etc.
//     // mode: 'cors', // no-cors, *cors, same-origin
//     // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//     // credentials: 'same-origin', // include, *same-origin, omit
//     headers: {
//       'Accept': 'application/json',
//       'X-API-KEY': '071e23d6-2497-491c-970e-c43c6f2dc6ec'
//     },
//   });

//   return response.json(); // parses JSON response into native JavaScript objects
// }

  const {
    loading: loadingModel,
    error: errorModel,
    data: dataModel,
    refetch: fetchModel,
  } = useQuery(
    gql`
      query ($modelId: ObjectId) {
        model(query: { _id: $modelId }) {
          _id
          name
          year
          manufacturerId
          prodStatus
          vehicleTypes
          isActive
          isComplete
          isPublished
          powertrainIsElectric
          powertrainIsPetrol
          powertrainIsDiesel
          powertrainIsHybrid
          powertrainIsPluginHybrid
          dataEntryNote
          modelSummary
          reliability
          futureValue
          aggregateMediaRating(input: $modelId)
          newCarMediaRating(input: $modelId)
          usedCarMediaRating(input: $modelId)
          rcDieselCO2Avg
          rcDieselCO2Min
          rcDieselCO2Max
          rcDieselMPGAvg
          rcDieselMPGMin
          rcDieselMPGMax
          rcPetrolCO2Avg
          rcPetrolCO2Min
          rcPetrolCO2Max
          rcPetrolMPGAvg
          rcPetrolMPGMin
          rcPetrolMPGMax
          rcHybridCO2Avg
          rcHybridCO2Min
          rcHybridCO2Max
          rcHybridMPGAvg
          rcHybridMPGMin
          rcHybridMPGMax
          rcPlugInHybridCO2Avg
          rcPlugInHybridCO2Min
          rcPlugInHybridCO2Max
          rcPlugInHybridBatteryRangeAvg
          rcPlugInHybridBatteryRangeMin
          rcPlugInHybridBatteryRangeMax
          rcPlugInHybridMPGAvg
          rcPlugInHybridMPGMin
          rcPlugInHybridMPGMax
          rcEVMpKWhAvg
          rcEVMpKWhMin
          rcEVMpKWhMax
          rcEVBatteryRangeAvg
          rcEVBatteryRangeMin
          rcEVBatteryRangeMax
          rcInsuranceGroupAvg
          rcInsuranceGroupMin
          rcInsuranceGroupMax
          rcAvgSMR1Year
          rcAvgSMR2Years
          rcAvgSMR3Years
          rcAvgSMR4Years
          rcAvgSMR5Years
          scNewCarExpertRating
          scNewCarExpertRatingScore
          scUsedCarExpertRating
          scUsedCarExpertRatingScore
          scNewCarMediaRating
          scNewCarMediaRatingScore
          scUsedCarMediaRating
          scUsedCarMediaRatingScore
          scSafetyRating
          scSafetyRatingScore
          scEcoRating
          scEcoRatingScore
          scReliabilityRating
          scReliabilityRatingScore
          scNewCarRunningCostRating
          scNewCarRunningCostRatingScore
          scUsedCarRunningCostRating
          scUsedCarRunningCostRatingScore
          scLastUpdated
        }
        reviews(query: { modelId: $modelId }, sortBy: DATE_DESC) {
          rating
          reviewSourceId
        }
        reviewsources(query: { isActive: true }, sortBy: NAME_ASC) {
          _id
          name
          logo
          isActive
          displayReview
          displayReviewScores
        }
      }
    `,
    {
      variables: {
        modelId: String(props.modelId),
      },
    }
  );

  const [
    updateModelCompleteStatus,
    { loading: mutationUpdateModelCompleteStatusLoading, error: mutationUpdateModelCompleteStatusError },
  ] = useMutation(gql`
    mutation UpdateModelCompleteStatus(
      $modelId: ObjectId
      $isComplete: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { isComplete: $isComplete }
      ) {
        _id
        isComplete
      }
    }
  `);

  const [
    updateModelProdStatus,
    { loading: mutationUpdateModelProdStatusLoading, error: mutationUpdateModelProdStatusError },
  ] = useMutation(gql`
    mutation UpdateModelProdStatus(
      $modelId: ObjectId
      $prodStatus: String
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { prodStatus: $prodStatus }
      ) {
        _id
        prodStatus
      }
    }
  `);

  const [
    updatePowertrainIsElectric,
    { loading: mutationUpdatePowertrainIsElectricLoading, error: mutationUpdatePowertrainIsElectricError },
  ] = useMutation(gql`
    mutation UpdatePowertrainIsElectric(
      $modelId: ObjectId
      $powertrainIsElectric: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { powertrainIsElectric: $powertrainIsElectric }
      ) {
        _id
        powertrainIsElectric
      }
    }
  `);

  const [
    updatePowertrainIsPetrol,
    { loading: mutationUpdatePowertrainIsPetrolLoading, error: mutationUpdatePowertrainIsPetrolError },
  ] = useMutation(gql`
    mutation UpdatePowertrainIsPetrol(
      $modelId: ObjectId
      $powertrainIsPetrol: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { powertrainIsPetrol: $powertrainIsPetrol }
      ) {
        _id
        powertrainIsPetrol
      }
    }
  `);

  const [
    updatePowertrainIsDiesel,
    { loading: mutationUpdatePowertrainIsDieselLoading, error: mutationUpdatePowertrainIsDieselError },
  ] = useMutation(gql`
    mutation UpdatePowertrainIsDiesel(
      $modelId: ObjectId
      $powertrainIsDiesel: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { powertrainIsDiesel: $powertrainIsDiesel }
      ) {
        _id
        powertrainIsDiesel
      }
    }
  `);

  const [
    updatePowertrainIsHybrid,
    { loading: mutationUpdatePowertrainIsHybridLoading, error: mutationUpdatePowertrainIsHybridError },
  ] = useMutation(gql`
    mutation UpdatePowertrainIsHybrid(
      $modelId: ObjectId
      $powertrainIsHybrid: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { powertrainIsHybrid: $powertrainIsHybrid }
      ) {
        _id
        powertrainIsHybrid
      }
    }
  `);

  const [
    updatePowertrainIsPluginHybrid,
    { loading: mutationUpdatePowertrainIsPluginHybridLoading, error: mutationUpdatePowertrainIsPluginHybridError },
  ] = useMutation(gql`
    mutation UpdatePowertrainIsPluginHybrid(
      $modelId: ObjectId
      $powertrainIsPluginHybrid: Boolean
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { powertrainIsPluginHybrid: $powertrainIsPluginHybrid }
      ) {
        _id
        powertrainIsPluginHybrid
      }
    }
  `);


  const [
    updateDataEntryNote,
    {
      loading: mutationUpdateDataEntryNoteLoading,
      error: mutationUpdateDataEntryNoteError,
    },
  ] = useMutation(gql`
    mutation UpdateDataEntryNote($modelId: ObjectId, $dataEntryNote: String) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { dataEntryNote: $dataEntryNote }
      ) {
        _id
        dataEntryNote
      }
    }
  `);

  const [
    updateModelSummary,
    {
      loading: mutationUpdateModelSummaryLoading,
      error: mutationUpdateModelSummaryError,
    },
  ] = useMutation(gql`
    mutation UpdateDataModelSummary($modelId: ObjectId, $modelSummary: String) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { modelSummary: $modelSummary }
      ) {
        _id
        modelSummary
      }
    }
  `);

  const [
    updateReliabilityData,
    {
      loading: mutationUpdateReliabilityDataLoading,
      error: mutationUpdateReliabilityDataError,
    },
  ] = useMutation(gql`
    mutation UpdateReliabilityData($modelId: ObjectId, $reliability: String) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { reliability: $reliability }
      ) {
        _id
        reliability
      }
    }
  `);

  useEffect(() => {
    if (loadingModel === false && dataModel) {

console.log(dataModel.model);

      setModel(dataModel.model);
      // setExpertRating(dataModel.model.expertRating);
      setNewCarMediaRating(dataModel.model.newCarMediaRating);
      setUsedCarMediaRating(dataModel.model.usedCarMediaRating);
      setAggregateMediaRating(dataModel.model.aggregateMediaRating);

      const reviewSourceList = dataModel.reviewsources.map((item) => item._id);

      const newReviews = dataModel.reviews.filter((item) =>
        reviewSourceList.includes(item.reviewSourceId)
      );

      setReviews(newReviews);
      setReviewSources(dataModel.reviewsources);

      setDataEntryNoteValue(
        dataModel.model.dataEntryNote === null ||
          dataModel.model.dataEntryNote === undefined
          ? ''
          : dataModel.model.dataEntryNote
      );
      setModelSummaryValue(
        dataModel.model.modelSummary === null ||
          dataModel.model.modelSummary === undefined
          ? ''
          : dataModel.model.modelSummary
      );

      setModelReliability(
        dataModel.model.reliability === null ||
          dataModel.model.reliability === undefined || dataModel.model.reliability === ""
          ? {}
          : JSON.parse(dataModel.model.reliability)
      );

      // setModelFutureValue(
      //   dataModel.model.futureValue === null ||
      //     dataModel.model.futureValue === undefined || dataModel.model.futureValue === ""
      //     ? {}
      //     : JSON.parse(dataModel.model.futureValue)
      // );

      // BMW 3-series
      // const modelFVSampleData = {
      //   vehicleId1: '1cd2d761-1525-4f2f-808a-7839cf7edb63',
      //   vehicleId1Data: [],
      //   vehicleId2: '7c71d52d-245e-4847-9d8c-3037a7a12f0c',
      //   vehicleId2Data: [],
      //   vehicleId3: '9128e18d-b497-4290-b825-cd3c362ba316',
      //   vehicleId3Data: [],
      //   vehicleId4: 'f84345ca-7a3f-479b-bf8e-eb319cf54562',
      //   vehicleId4Data: [],
      //   vehicleId5: '62440689-8a08-4bd0-b6f3-b6b0e3422410',
      //   vehicleId5Data: [],
      //   vehicleId6: '',
      //   vehicleId6Data: [],
      //   vehicleId7: '',
      //   vehicleId7Data: [],
      //   vehicleId8: '',
      //   vehicleId8Data: [],
      //   vehicleId9: '',
      //   vehicleId9Data: [],
      //   vehicleId10: '',
      //   vehicleId10Data: []
      // };

      // Audi A4
      // const modelFVSampleData = {
      //   vehicleId1: '617dd8ee-3d15-4908-a091-a562ca675ecc',
      //   vehicleId1Data: [],
      //   vehicleId2: '59354ee3-6381-445b-aa61-e8b8d329ce85',
      //   vehicleId2Data: [],
      //   vehicleId3: '0daf1cdf-5b21-4ad9-9113-d562c97a50f4',
      //   vehicleId3Data: [],
      //   vehicleId4: 'c13fa65b-e6ab-4d68-bdff-cc4a2abdb815',
      //   vehicleId4Data: [],
      //   vehicleId5: '784a8a53-e516-4bf7-a1ad-18cefad360e6',
      //   vehicleId5Data: [],
      //   vehicleId6: '70ab3c24-29d4-41a5-afaf-a3d0846015af',
      //   vehicleId6Data: [],
      //   vehicleId7: '',
      //   vehicleId7Data: [],
      //   vehicleId8: '',
      //   vehicleId8Data: [],
      //   vehicleId9: '',
      //   vehicleId9Data: [],
      //   vehicleId10: '',
      //   vehicleId10Data: []
      // };

      // Mercedes C-class
      // const modelFVSampleData = {
      //   vehicleId1: '5b9f5d15-306a-432a-98bc-e24295db5fa0',
      //   vehicleId1Data: [],
      //   vehicleId2: 'e0686273-70f0-4c7e-8d6c-b6aed579dda0',
      //   vehicleId2Data: [],
      //   vehicleId3: '08205ea9-9c76-4cdc-a2ba-252b30a90837',
      //   vehicleId3Data: [],
      //   vehicleId4: '125eb492-d033-4b60-a748-ea7202950256',
      //   vehicleId4Data: [],
      //   vehicleId5: '9f891cb8-908f-4eaa-85d8-d97ff3c4dc15',
      //   vehicleId5Data: [],
      //   vehicleId6: 'b53b30bd-26a0-486f-a105-2b5a4dbdf47b',
      //   vehicleId6Data: [],
      //   vehicleId7: '',
      //   vehicleId7Data: [],
      //   vehicleId8: '',
      //   vehicleId8Data: [],
      //   vehicleId9: '',
      //   vehicleId9Data: [],
      //   vehicleId10: '',
      //   vehicleId10Data: []
      // };

      // Ford Fiesta
      // const modelFVSampleData = {
      //   vehicleId1: '01378762-bed6-48b7-9c4f-1819c2da3c5a',
      //   vehicleId1Data: [],
      //   vehicleId2: '1060c80b-464b-4136-be13-c79f8d607611',
      //   vehicleId2Data: [],
      //   vehicleId3: '9ddc4a3d-bff5-4697-bf9f-9258fd0b38a9',
      //   vehicleId3Data: [],
      //   vehicleId4: '659ea44b-1e57-42e3-b12c-5c6a882bcf7c',
      //   vehicleId4Data: [],
      //   vehicleId5: 'c2026ff7-ecd5-4a80-9ad5-32195d29fd12',
      //   vehicleId5Data: [],
      //   vehicleId6: '',
      //   vehicleId6Data: [],
      //   vehicleId7: '',
      //   vehicleId7Data: [],
      //   vehicleId8: '',
      //   vehicleId8Data: [],
      //   vehicleId9: '',
      //   vehicleId9Data: [],
      //   vehicleId10: '',
      //   vehicleId10Data: []
      // };

      // Fiat Tipo
      // const modelFVSampleData = {
      //   vehicleId1: 'bb17feb8-c63c-4e97-909d-7928999fffa5',
      //   vehicleId1Data: [],
      //   vehicleId2: 'fbc7335a-ce41-4679-a065-99c22d9e5c84',
      //   vehicleId2Data: [],
      //   vehicleId3: '78e36e2a-8363-41d0-ac6a-3fbafe35711f',
      //   vehicleId3Data: [],
      //   vehicleId4: 'e114cb4c-adc0-4f16-ba41-0aec1bab791b',
      //   vehicleId4Data: [],
      //   vehicleId5: '6f2b1a6e-19ba-4622-92bd-239fae716552',
      //   vehicleId5Data: [],
      //   vehicleId6: '',
      //   vehicleId6Data: [],
      //   vehicleId7: '',
      //   vehicleId7Data: [],
      //   vehicleId8: '',
      //   vehicleId8Data: [],
      //   vehicleId9: '',
      //   vehicleId9Data: [],
      //   vehicleId10: '',
      //   vehicleId10Data: []
      // };

// Tesla Model 3
  // const modelFVSampleData = {
  //   vehicleId1: '7a559699-8f67-42a9-8c84-33bcd4d4eae2',
  //   vehicleId1Data: [],
  //   vehicleId2: 'ddd2ca9a-4bd7-4bec-a6d0-2ec1b64f60d7',
  //   vehicleId2Data: [],
  //   vehicleId3: '0659ad4a-627f-444d-9023-6c75a48210b3',
  //   vehicleId3Data: [],
  //   vehicleId4: '',
  //   vehicleId4Data: [],
  //   vehicleId5: '',
  //   vehicleId5Data: [],
  //   vehicleId6: '',
  //   vehicleId6Data: [],
  //   vehicleId7: '',
  //   vehicleId7Data: [],
  //   vehicleId8: '',
  //   vehicleId8Data: [],
  //   vehicleId9: '',
  //   vehicleId9Data: [],
  //   vehicleId10: '',
  //   vehicleId10Data: []
  // };

  // setModelFutureValue(modelFVSampleData);
  // setCurrentModelFV(modelFVSampleData);

  // console.log(modelFVSampleData);

// Below adding reliability to model

// setModelReliability({ 
//   CarMake: 'BMW', 
//   CarModel: '3 SERIES',
//   TotalClaims: 648,
//   AverageRepairCost: 1034.77,
//   WarrantyReliabilityScore: -30.5,
//   Sections: [{
//     SectionName: 'Engine',
//     NoOfClaims: 115,
//     RepairCost: 2748.99,
//   },
//   {
//     SectionName: 'Gearbox',
//     NoOfClaims: 18,
//     RepairCost: 1902.19,
//   },
//   {
//     SectionName: 'Electrical system	',
//     NoOfClaims: 112,
//     RepairCost: 370.50,
//   },
//   {
//     SectionName: 'Suspension',
//     NoOfClaims: 100,
//     RepairCost:371.96,
//   },
//   {
//     SectionName: 'Braking system',
//     NoOfClaims: 85,
//     RepairCost: 263.36,
//   },
//   {
//     SectionName: 'Fuel system',
//     NoOfClaims: 40,
//     RepairCost: 551.62,
//   }],
//   DateUpdated: '1/10/2022'
// });

// Above adding reliability to model

      // const createAverageReviewScore = (items, prop) => {
      //   const numRated = newReviews.filter((item) => item.rating > 0).length;

      //   if (numRated > 0) {
      //     return Math.round(
      //       (items.reduce((a, b) => a + b[prop], 0) / numRated) * 10
      //     );
      //   }

      //   return 0;
      // };

      // const averageRating = createAverageReviewScore(
      //   newReviews.filter((item) => item.rating > 0),
      //   'rating'
      // );

      // setAverageRating(averageRating);
    }
  }, [loadingModel, dataModel]);

  if (errorModel) {
    return <div>encountered an error: {errorModel}</div>;
  }
  if (mutationUpdateModelCompleteStatusError) {
    return <p>Error: {mutationUpdateModelCompleteStatusError}</p>;
  }
  if (mutationUpdateModelProdStatusError) {
    return <p>Error: {mutationUpdateModelProdStatusError}</p>;
  }
  if (mutationUpdatePowertrainIsElectricError) {
    return <p>Error: {mutationUpdatePowertrainIsElectricError}</p>;
  }
  if (mutationUpdatePowertrainIsPetrolError) {
    return <p>Error: {mutationUpdatePowertrainIsPetrolError}</p>;
  }
  if (mutationUpdatePowertrainIsDieselError) {
    return <p>Error: {mutationUpdatePowertrainIsDieselError}</p>;
  }
  if (mutationUpdatePowertrainIsHybridError) {
    return <p>Error: {mutationUpdatePowertrainIsHybridError}</p>;
  }
  if (mutationUpdatePowertrainIsPluginHybridError) {
    return <p>Error: {mutationUpdatePowertrainIsPluginHybridError}</p>;
  }
  if (mutationUpdateDataEntryNoteError) {
    return <p>Error: {mutationUpdateDataEntryNoteError}</p>;
  }
  if (mutationUpdateModelSummaryError) {
    return <p>Error: {mutationUpdateModelSummaryError}</p>;
  }
  if (mutationUpdateReliabilityDataError) {
    return <p>Error: {mutationUpdateReliabilityDataError}</p>;
  }

  async function handleCompleteButtonClick(setStatusTo) {
    const result = await updateModelCompleteStatus({
      variables: {
        modelId: model._id,
        isComplete: setStatusTo === 'complete' ? true : false,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handleProdStatusButtonClick(setStatusTo) {
    const result = await updateModelProdStatus({
      variables: {
        modelId: model._id,
        prodStatus: setStatusTo,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handlePowertrainIsElectricButtonClick(setValueTo) {
    const result = await updatePowertrainIsElectric({
      variables: {
        modelId: model._id,
        powertrainIsElectric: setValueTo,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handlePowertrainIsPetrolButtonClick(setValueTo) {
    const result = await updatePowertrainIsPetrol({
      variables: {
        modelId: model._id,
        powertrainIsPetrol: setValueTo,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handlePowertrainIsDieselButtonClick(setValueTo) {
    const result = await updatePowertrainIsDiesel({
      variables: {
        modelId: model._id,
        powertrainIsDiesel: setValueTo,
      },
    });

console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handlePowertrainIsHybridButtonClick(setValueTo) {
    const result = await updatePowertrainIsHybrid({
      variables: {
        modelId: model._id,
        powertrainIsHybrid: setValueTo,
      },
    });

console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  async function handlePowertrainIsPluginHybridButtonClick(setValueTo) {
    const result = await updatePowertrainIsPluginHybrid({
      variables: {
        modelId: model._id,
        powertrainIsPluginHybrid: setValueTo,
      },
    });

console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });
  }

  function handleDataEntryNoteChange(event) {
    setDataEntryNoteValue(event.target.value);
  }

  function handleEditDataEntryNoteButtonClick() {
    setShowDataEntryNoteEditor(true);
  }

  async function handleUpdateDataEntryNoteButtonClick() {
    // console.log('save this: ', dataEntryNoteValue);

    const result = await updateDataEntryNote({
      variables: {
        modelId: model._id,
        dataEntryNote: dataEntryNoteValue,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });

    setShowDataEntryNoteEditor(false);
  }

  function handleCancelDataEntryNoteButtonClick() {
    setDataEntryNoteValue(model.dataEntryNote);
    setShowDataEntryNoteEditor(false);
  }

  function handleModelSummaryChange(event) {
    setModelSummaryValue(event.target.value);
  }

  function handleEditModelSummaryButtonClick() {
    setShowModelSummaryEditor(true);
  }

  async function handleUpdateModelSummaryButtonClick() {
    // console.log('save this: ', modelSummaryValue);

    const result = await updateModelSummary({
      variables: {
        modelId: model._id,
        modelSummary: modelSummaryValue,
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });

    setShowModelSummaryEditor(false);
  }

  function handleCancelModelSummaryButtonClick() {
    setModelSummaryValue(model.modelSummary);
    setShowModelSummaryEditor(false);
  }

  // Handle Clear Reliability
  async function handleClearReliabilityDataButtonClick() {
    // console.log('save this: ', modelReliability);

    const result = await updateReliabilityData({
      variables: {
        modelId: model._id,
        reliability: "",
      },
    });

    console.log('result: ', result);

    fetchModel({
      variables: {
        modelId: model._id,
      },
    });

    setModelReliability({});
  }

  // Handle Save Reliability
  async function handleSaveReliabilityDataButtonClick() {

    if(currentMotorEasyModelData[currentMotorEasyModelIndex] !== undefined) {
      let tempModelReliability = currentMotorEasyModelData[currentMotorEasyModelIndex];

      tempModelReliability.CarMake = currentMotorEasyMake;

      const currentYear = new Date().getFullYear();
      const nameOfMonthUK = new Intl.DateTimeFormat('en-UK', {month: 'long'}).format(
        new Date(),
      );

      tempModelReliability.DateUpdated = nameOfMonthUK + ' ' + currentYear;

// console.log('save this: ', tempModelReliability);
// console.log('save this: ', JSON.stringify(tempModelReliability));

      const result = await updateReliabilityData({
        variables: {
          modelId: model._id,
          reliability: JSON.stringify(tempModelReliability),
        },
      });

      console.log('result: ', result);

      fetchModel({
        variables: {
          modelId: model._id,
        },
      });

      setModelReliability(tempModelReliability);

    }
  }

  async function handleDataChange() {
    if (!loadingModel) {
      fetchModel({
        variables: {
          modelId: model._id,
        },
      });
    }
  }

  return (
    <>
      {loadingModel || mutationUpdateModelCompleteStatusLoading || mutationUpdateModelProdStatusLoading || mutationUpdatePowertrainIsElectricLoading || mutationUpdatePowertrainIsPetrolLoading || mutationUpdatePowertrainIsDieselLoading || mutationUpdatePowertrainIsHybridLoading || mutationUpdatePowertrainIsPluginHybridLoading ? (
        <ClipLoader
        loading={true}
        cssOverride={cssoverride}
      />
      ) : (
        <>
          <div className="border px-4 pt-1 pb-4 mt-4 shadow" style={{ backgroundColor: model.isComplete ? '#d4edda' : '' }}>
            <Container fluid className='mt-4'>
              <Row>
                <Col>
                  <h6
                    style={{
                      color: '#6c757d',
                    }}
                  >
                    {props.manufacturerName}
                  </h6>
                  <h2
                    className='display-5'
                    style={{
                      color: model.isComplete ? '#155724' : '',
                    }}
                  >
                    {model.name} ({model.year})
                  </h2>
                  {model.vehicleTypes
                    ? model.vehicleTypes.map((vehicleType) => (
                        <p key={vehicleType} className='vehicle-type'>
                          {vehicleType}
                        </p>
                      ))
                    : ''}
                </Col>
                <Col>
                  <div className='float-end me-2'>
                    <p
                      className='vehicle-type'
                      style={{
                        display: 'inline-block',
                        marginRight: '10px',
                        color: model.isComplete ? '#155724' : '',
                        verticalAlign: 'top',
                        backgroundColor: '#ffffff'
                      }}
                    >
                      {model.isComplete ? 'Complete' : 'Incomplete'}
                    </p>
                    <Button
                      color='primary'
                      size='sm'
                      className=''
                      onClick={() =>
                        handleCompleteButtonClick(
                          model.isComplete ? 'incomplete' : 'complete'
                        )
                      }
                    >
                      Mark as {model.isComplete ? 'incomplete' : 'complete'}
                    </Button>
                  </div>
                  <div className='float-end me-2'>
                    <p
                      className='vehicle-type'
                      style={{
                        display: 'inline-block',
                        marginRight: '10px',
                        color: model.prodStatus === 'current' ? '#155724' : '#ff0000',
                        verticalAlign: 'top',
                        backgroundColor: '#ffffff'
                      }}
                    >
                      {model.prodStatus !== null ? model.prodStatus === 'current' ? 'Current' : 'Discontinued' : 'Not yet set'}
                    </p>
                    <Button
                      color='primary'
                      size='sm'
                      className=''
                      onClick={() =>
                        handleProdStatusButtonClick(
                          model.prodStatus === 'current' ? 'discontinued' : 'current'
                        )
                      }
                    >
                      Set to {model.prodStatus === 'current' ? 'Discontinued' : 'Current'}
                    </Button>
                  </div>
                  <div className='float-end me-2'>
                      <ButtonGroup>
                        <Button outline size="sm" color="primary" active={model.powertrainIsElectric} onClick={() => handlePowertrainIsElectricButtonClick(model.powertrainIsElectric ? false : true)}>{model.powertrainIsElectric && `${String.fromCharCode(10003)} `}Electric</Button>
                        <Button outline size="sm" color="primary" active={model.powertrainIsPetrol} onClick={() => handlePowertrainIsPetrolButtonClick(model.powertrainIsPetrol ? false : true)}>{model.powertrainIsPetrol && `${String.fromCharCode(10003)} `}Petrol</Button>
                        <Button outline size="sm" color="primary" active={model.powertrainIsDiesel} onClick={() => handlePowertrainIsDieselButtonClick(model.powertrainIsDiesel ? false : true)}>{model.powertrainIsDiesel && `${String.fromCharCode(10003)} `}Diesel</Button>
                        <Button outline size="sm" color="primary" active={model.powertrainIsHybrid} onClick={() => handlePowertrainIsHybridButtonClick(model.powertrainIsHybrid ? false : true)}>{model.powertrainIsHybrid && `${String.fromCharCode(10003)} `}Hybrid</Button>
                        <Button outline size="sm" color="primary" active={model.powertrainIsPluginHybrid} onClick={() => handlePowertrainIsPluginHybridButtonClick(model.powertrainIsPluginHybrid ? false : true)}>{model.powertrainIsPluginHybrid && `${String.fromCharCode(10003)} `}Plug-in hybrid</Button>
                      </ButtonGroup>
                  </div>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col>
                <table className='expert-rating-scorecard' style={{ backgroundColor:'#ffffff' }}>
                  <thead>
                    <tr>
                      <th>Expert Rating Scorecard</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>New car score:</td>
                      <td>{model.scNewCarExpertRating ? model.scNewCarExpertRating + '%' : '-'}</td>
                      <td className='scorecard-rating'>{model.scNewCarExpertRatingScore ? model.scNewCarExpertRatingScore : '-'}</td>
                    </tr>
                    <tr>
                      <td>Used car score:</td>
                      <td>{model.scUsedCarExpertRating ? model.scUsedCarExpertRating + '%' : '-'}</td>
                      <td className='scorecard-rating'>{model.scUsedCarExpertRatingScore ? model.scUsedCarExpertRatingScore : '-'}</td>
                    </tr>
                    <tr>
                      <td>Media rating:</td>
                      <td>{model.prodStatus === "current" ? (model.scNewCarMediaRating ? model.scNewCarMediaRating + '%' : '-') : (model.scUsedCarMediaRating ? model.scUsedCarMediaRating + '%' : '-')}</td>
                      <td className='scorecard-rating'>{model.prodStatus === "current" ? (model.scNewCarMediaRatingScore ? model.scNewCarMediaRatingScore : '-') : (model.scUsedCarMediaRatingScore ? model.scUsedCarMediaRatingScore : '-')}</td>
                    </tr>
                    <tr>
                      <td>Safety Rating:</td>
                      <td>{model.scSafetyRating ? model.scSafetyRating + '%' : '-'}</td>
                      <td className='scorecard-rating'>{model.scSafetyRatingScore ? model.scSafetyRatingScore : '-'}</td>
                    </tr>
                    <tr>
                      <td>Eco Rating:</td>
                      <td>{model.scEcoRating ? model.scEcoRating + '%' : '-'}</td>
                      <td className='scorecard-rating'>{model.scEcoRatingScore ? model.scEcoRatingScore : '-'}</td>
                    </tr>
                    <tr>
                      <td>Reliability Rating:</td>
                      <td>{model.scReliabilityRating ? model.scReliabilityRating + '%' : '-'}</td>
                      <td className='scorecard-rating'>{model.scReliabilityRatingScore ? model.scReliabilityRatingScore : '-'}</td>
                    </tr>
                    <tr>
                      <td>Running Costs:</td>
                      <td>{model.prodStatus === "current" ? (model.scNewCarRunningCostRating ? model.scNewCarRunningCostRating + '%' : '-') : (model.scUsedCarRunningCostRating ? model.scUsedCarRunningCostRating + '%' : '-')}</td>
                      <td className='scorecard-rating'>{model.prodStatus === "current" ? (model.scNewCarRunningCostRatingScore ? model.scNewCarRunningCostRatingScore : '-') : (model.scUsedCarRunningCostRatingScore ? model.scUsedCarRunningCostRatingScore : '-')}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{fontSize: '0.75em', color: '#a4a4a4'}}>Last updated: {model.scLastUpdated ? model.scLastUpdated : 'never'}</td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
              <Col>
              {newCarMediaRating > 0 && (
                <>
                  <div>
                    <h5 style={{ marginBottom: 0 }}>
                      New Car Media Rating:{' '}
                      <span className='vehicle-type'>{newCarMediaRating}%</span>
                    </h5>
                  </div>
                </>
              )}
              {usedCarMediaRating > 0 && (
                <>
                  <hr />
                  <div>
                    <h5 style={{ marginBottom: 0 }}>
                      Used Car Media Rating:{' '}
                      <span className='vehicle-type'>{usedCarMediaRating}%</span>
                    </h5>
                  </div>
                </>
              )}
              {aggregateMediaRating > 0 && (
                <>
                  <hr />
                  <div>
                    <h5 style={{ marginBottom: 0 }}>
                      Aggregate Media Rating:{' '}
                      <span className='vehicle-type'>{aggregateMediaRating}%</span>
                    </h5>
                    <p style={{ color: '#a4a4a4' }}>
                        {reviews.filter((item) => item.rating > 0).length +
                          ` ratings from ` +
                          reviews.length +
                          ` reviews`}
                      </p>
                  </div>
                </>
              )}
              </Col>
              </Row>
              <hr />
              {mutationUpdateDataEntryNoteLoading ? (
                <ClipLoader
                loading={true}
                cssOverride={cssoverride}
              />
              ) : (
                <>
                  {!showDataEntryNoteEditor ? (
                    <div className=''>
                      <textarea
                        className={
                          dataEntryNoteValue === ''
                            ? 'model-entry-note-blank'
                            : 'model-entry-note'
                        }
                        readOnly={true}
                        rows={2}
                        value={
                          dataEntryNoteValue === ''
                            ? 'Data entry notes appear here...'
                            : dataEntryNoteValue
                        }
                      ></textarea>
                      <Button
                        size='sm'
                        onClick={() => handleEditDataEntryNoteButtonClick()}
                      >
                        Edit data entry note
                      </Button>
                    </div>
                  ) : (
                    <div className=''>
                      <textarea
                        className='model-entry-note'
                        style={{ backgroundColor: 'white' }}
                        rows={4}
                        value={dataEntryNoteValue}
                        onChange={(event) => handleDataEntryNoteChange(event)}
                      ></textarea>
                      <Button
                        size='sm'
                        style={{ marginRight: 10 }}
                        onClick={() => handleCancelDataEntryNoteButtonClick()}
                      >
                        Cancel
                      </Button>
                      <Button
                        size='sm'
                        className='btn btn-success'
                        onClick={() => handleUpdateDataEntryNoteButtonClick()}
                      >
                        Update
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Container>
          </div>
          <div className='border px-4 pt-1 pb-4 mt-4 shadow' key='model-summary-block'>
            <h4 className='mt-3 mb-4'>Model Summary</h4>
            {mutationUpdateModelSummaryLoading ? (
                <ClipLoader
                loading={true}
                cssOverride={cssoverride}
              />
            ) : (
              <>
                {!showModelSummaryEditor ? (
                  <>
                    <hr/>
                    <textarea
                      className={
                        modelSummaryValue === ''
                          ? 'model-entry-note-blank'
                          : 'model-entry-note'
                      }
                      readOnly={true}
                      rows={3}
                      value={
                        modelSummaryValue === ''
                          ? 'Model summary appear here...'
                          : modelSummaryValue
                      }
                    ></textarea>
                    <Button
                      size='sm'
                      onClick={() => handleEditModelSummaryButtonClick()}
                    >
                      Edit model summary
                    </Button>
                  </>
                ) : (
                  <div className=''>
                    <textarea
                      className='model-entry-note'
                      style={{ backgroundColor: 'white' }}
                      rows={10}
                      value={modelSummaryValue}
                      onChange={(event) => handleModelSummaryChange(event)}
                    ></textarea>
                    <Button
                      size='sm'
                      style={{ marginRight: 10 }}
                      onClick={() => handleCancelModelSummaryButtonClick()}
                    >
                      Cancel
                    </Button>
                    <Button
                      size='sm'
                      className='btn btn-success'
                      onClick={() => handleUpdateModelSummaryButtonClick()}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
          <VehicleDataEuroNCAPs
            modelId={model._id}
            onChange={handleDataChange}
          />
          <VehicleDataGreenNCAPs
            modelId={model._id}
            onChange={handleDataChange}
          />
          <VehicleDataReviews
            modelId={model._id}
            reviewSources={reviewsources}
            titleString={
              props.manufacturerName +
              ` ` +
              model.name +
              ` (` +
              model.year +
              `)`
            }
            onChange={handleDataChange}
          />
          <VehicleDataRunningCosts
            modelId={model._id}
            model={model}
            onChange={handleDataChange}
            loadingModel={loadingModel}
          />
          {/* <div className='border px-4 pt-1 pb-4 mt-4 shadow'>
            <h4 className='mt-3 mb-4'>Running Costs from CVD</h4>
            <hr/>
            {mutationUpdateModelSummaryLoading ? (
                  <ClipLoader
                  loading={true}
                  cssOverride={cssoverride}
                />
              ) : (
                <>
                  {!showRunningCostsEditor ? (
                    <Row style={{ justifyContent: 'flex-end', marginLeft: 0, marginRight: 0 }}>
                      <Button
                        color='primary'
                        size='sm'
                        className='float-end'
                        onClick={() => handleEditRunningCostsButtonClick()}
                      >
                        Edit running costs
                      </Button>
                    </Row>
                  ) : (
                    <div className=''>
                      <p>Enter whole numbers for all fields, with the exception of EV m/KWh which should be entered to 1 decimal point accuracy (e.g. 12.1).<br/>Zeros are significant, so if a running cost is not defined, leave it blank.</p>
                      <hr/>
                      <Row style={{ justifyContent: 'flex-end', marginLeft: 0, marginRight: 0 }}>
                        <Button
                          size='sm'
                          style={{ marginRight: 10 }}
                          onClick={() => handleCancelEditRunningCostsButtonClick()}
                        >
                          Cancel
                        </Button>
                        <Button
                          size='sm'
                          className='btn btn-success'
                          onClick={() => {}}
                        >
                          Update
                        </Button>
                        </Row>
                    </div>
                  )}
                </>
              )}
          </div> */}

          <div className='border px-4 pt-1 pb-4 mt-4 mb-5 shadow' key='model-reliability-block'>
            <h4 className='mt-3 mb-4'>Reliability Data from MotorEasy</h4>
            {mutationUpdateModelSummaryLoading ? (
                <ClipLoader
                loading={true}
                cssOverride={cssoverride}
              />
            ) : (
              <>
                <hr/>
                <Row>
                <Col>
                  <p>
                    <strong>Current data</strong>
                  </p>
                  {Object.keys(modelReliability).length === 0 && <p>No data available...</p>}
                  {Object.keys(modelReliability).length !== 0 && (
                    <>
                    <p>Brand: {modelReliability.CarMake}<br/>Model: {modelReliability.CarModel}</p>
                    <p>Last updated: {modelReliability.DateUpdated}</p>
                    <p>Total claims: {modelReliability.TotalClaims}<br/>Average Repair Cost: £{modelReliability.AverageRepairCost.toFixed(2)}<br/>Warranty Reliability Score (raw): {modelReliability.WarrantyReliabilityScore}<br/>Warranty Reliability Score (0 - 100% scale): {Math.round(modelReliability.WarrantyReliabilityScore*10)}%</p>
                    <table>
                          <thead>
                        <tr>
                          <th>Section</th><th>No. of Claims</th><th>Repair cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {modelReliability.Sections.map((section) => (
                          <>
                            <tr>
                              <td key={section.SectionName}>{section.SectionName}</td><td style={{ textAlign: 'center' }}>{section.NoOfClaims}</td><td style={{ textAlign: 'right' }}>£{section.RepairCost.toFixed(2)}</td>
                            </tr>
                          </>
                        ))}
                        </tbody>
                        </table>
                      </>
                    )}
                  </Col>   
                  <Col>
                  <p>
                    <strong>Query for new data</strong>
                  </p>                
                    {/* <p>
                      <strong>Car brand</strong>
                    </p> */}
                    <div className='form-group mb-0'>
                      <select
                        className='form-control mb-3'
                        onChange={handleMotorEasyMakeChange}
                        value={currentMotorEasyMake}
                      >
                        {motorEasyMakes.map((make) => (
                          <option key={make} value={make}>
                            {make}
                          </option>
                        ))}
                      </select>
                    </div>

              {currentMotorEasyMake !== 'Select a brand' && currentMotorEasyModelData.length > 0 && (
                  <>
                  {/* <p>
                    <strong>Model</strong>
                  </p> */}
                  <div className='form-group mb-0'>
                    <select
                      className='form-control mb-3'
                      onChange={handleMotorEasyModelChange}
                      value={currentMotorEasyModelIndex}
                    >
                      {currentMotorEasyModelData.map((mEmodel, index) => (
                        <option key={mEmodel.CarModel} value={index}>
                          {mEmodel.CarModel}
                        </option>
                      ))}
                    </select>
                  </div>
                  </>
                )}
                
                  {currentMotorEasyModelData.length > 0 && currentMotorEasyModelData[0].CarModel && (
                    <>
                      <p>Total claims: {currentMotorEasyModelData[currentMotorEasyModelIndex].TotalClaims}<br/>Average Repair Cost: £{currentMotorEasyModelData[currentMotorEasyModelIndex].AverageRepairCost.toFixed(2)}<br/>Warranty Reliability Score (raw): {currentMotorEasyModelData[currentMotorEasyModelIndex].WarrantyReliabilityScore}<br/>Warranty Reliability Score (0 - 100% scale): {Math.round(currentMotorEasyModelData[currentMotorEasyModelIndex].WarrantyReliabilityScore*10)}%</p>
                      <table>
                        <thead>
                      <tr>
                        <th>Section</th><th>No. of Claims</th><th>Repair cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {currentMotorEasyModelData[currentMotorEasyModelIndex].Sections.map((section) => (
                        <>
                          <tr>
                            <td key={section.SectionName}>{section.SectionName}</td><td style={{ textAlign: 'center' }}>{section.NoOfClaims}</td><td style={{ textAlign: 'right' }}>£{section.RepairCost.toFixed(2)}</td>
                          </tr>
                        </>
                      ))}
                      </tbody>
                      </table>
                     </>
                  )}
                  </Col>
                  </Row>
                  <hr/>
                  <Row style={{ justifyContent: 'flex-end', marginLeft: 0, marginRight: 0 }}>
                  {mutationUpdateReliabilityDataLoading ? (
                      <ClipLoader
                      loading={true}
                      cssOverride={cssoverride}
                    />
                    ) : (
                      <>
                      <Button
                        size='sm'
                        className='me-2 mb-3 float-end btn btn-danger btn-sm'
                        onClick={() => handleClearReliabilityDataButtonClick()}
                      >
                        Clear data
                      </Button>
                      <Button
                        color='primary'
                        size='sm'
                        className='btn btn-primary btn-success btn-sm float-end mb-3'
                        onClick={() => handleSaveReliabilityDataButtonClick()}
                      >
                        Save data
                      </Button>
                      </>
                    )
                  }
                  </Row>
                </>
            )}
          </div>
        </>
      )}
    </>
  );
}
