import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Input = ({ name, label, error, ...rest }) => {
  return (
    <div className='form-group'>
      {label !== '' && <label htmlFor={name}>{label}</label>}
      <input {...rest} name={name} id={uuidv4()} className='form-control' />
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Input;
