import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Button, Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';

class EditGreenNCAPForm extends Form {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      ...this.props,
      isOpen: false,
      showDeleteConfirm: false,
      data: {
        url: '',
        starRating: '',
        date: new Date(),
        modelReviewed: '',
        cleanAirIndex: '',
        energyEfficiencyIndex: '',
        greenhouseGasIndex: '',
        isValid: '',
      },
      validOptions: [
        { _id: 'edit-yes', name: 'Yes' },
        { _id: 'edit-no', name: 'No' },
      ],
      errors: {},
    };
  }

  schema = {
    _id: Joi.string(),
    modelId: Joi.string(),
    url: Joi.string().min(10).max(2048).required().label('Review url'),
    starRating: Joi.number()
      .precision(1)
      .required()
      .min(0)
      .max(5)
      .label('Star rating'),
    date: Joi.date().required().label('Rating date'),
    modelReviewed: Joi.string()
      .min(0)
      .max(255)
      .allow('')
      .allow(null)
      .optional()
      .label('Model reviewed'),
    cleanAirIndex: Joi.number()
      .precision(1)
      .required()
      .min(0)
      .max(10)
      .label('Clean Air Index'),
    energyEfficiencyIndex: Joi.number()
      .precision(1)
      .required()
      .min(0)
      .max(10)
      .label('Energy Efficiency Index'),
    greenhouseGasIndex: Joi.number()
      .precision(1)
      .required()
      .min(0)
      .max(10)
      .label('Greenhouse Gas Index'),
    isValid: Joi.string().required().label('Valid'),
  };

  async componentDidMount() {
    this.setState({
      data: this.mapToViewModel(this.state.greenNCAPReport),
      isOpen: false,
    });
  }

  mapToViewModel(greenNCAPReport) {
    return {
      _id: greenNCAPReport._id,
      modelId: greenNCAPReport.modelId,
      url: greenNCAPReport.url,
      date: new Date(greenNCAPReport.date),
      starRating: greenNCAPReport.starRating,
      modelReviewed: greenNCAPReport.modelReviewed,
      cleanAirIndex: greenNCAPReport.cleanAirIndex,
      energyEfficiencyIndex: greenNCAPReport.energyEfficiencyIndex,
      greenhouseGasIndex:
        greenNCAPReport.greenhouseGasIndex != null
          ? greenNCAPReport.greenhouseGasIndex
          : 0,
      isValid: greenNCAPReport.isValid ? 'edit-yes' : 'edit-no',
    };
  }

  async toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  doSubmit = async () => {
    let { data } = this.state;

    data.isValid === 'edit-yes'
      ? (data.isValid = true)
      : (data.isValid = false);

    data.starRating = data.starRating - 0;
    data.cleanAirIndex = data.cleanAirIndex - 0;
    data.energyEfficiencyIndex = data.energyEfficiencyIndex - 0;
    data.greenhouseGasIndex = data.greenhouseGasIndex - 0;

    this.props.onUpdate(data);
    this.setState({ data: this.mapToViewModel(data) });
    this.toggle();
  };

  handleDeleteBtnClick = () => {
    this.setState({ showDeleteConfirm: true });
  };

  handleDeleteConfirmBtnYesClick = async () => {
    this.props.onDelete(this.state.greenNCAPReport._id);
  };

  handleDeleteConfirmBtnNoClick = () => {
    this.setState({ showDeleteConfirm: false });
  };

  handleDateChange = (date) => {
    let newData = { ...this.state.data };
    newData.date = date;
    this.setState({ data: newData });
  };

  render() {
    const { data, showDeleteConfirm } = this.state;
    // const { greenNCAPReport } = this.props;

    return (
      <>
        <Card className='mt-3'>
          <CardHeader
            className='card-header-review'
            style={{
              backgroundColor:
                data.isValid === 'edit-yes' ? '#d4edda' : '#f8d7da',
              borderColor: data.isValid === 'edit-yes' ? '#c3e6cb' : '#f5c6cb',
            }}
          >
            <Button
              color=''
              onClick={this.toggle}
              style={{ textAlign: 'left' }}
              block
            >
              <h5
                style={{
                  color: data.isValid === 'edit-yes' ? '#155724' : '#721c24',
                }}
              >
                Green NCAP report{' '}
                <span className='float-end'>
                  <span className='vehicle-type'>{data.starRating} stars</span>{' '}
                  {data.date ? data.date.toLocaleDateString() : ''}
                </span>
              </h5>
            </Button>
          </CardHeader>
          <Collapse isOpen={this.state.isOpen}>
            <CardBody>
              <form className='mb-4' onSubmit={this.handleSubmit}>
                <div className='col'>
                  {this.renderInput('url', 'Review url')}
                  {this.renderInput('starRating', 'Star rating')}
                  <div className='form-group'>
                    <label>Rating date </label>
                    <DatePicker
                      selected={data.date}
                      dateFormat='dd/MM/yyyy'
                      className='form-control'
                      onChange={this.handleDateChange}
                    />
                  </div>
                  {this.renderInput('modelReviewed', 'Model reviewed')}
                  {this.renderInput('cleanAirIndex', 'Clean Air Index')}
                  {this.renderInput(
                    'energyEfficiencyIndex',
                    'Energy Efficiency Index'
                  )}
                  {this.renderInput(
                    'greenhouseGasIndex',
                    'Greenhouse Gas Index'
                  )}
                  {this.renderSelect(
                    'isValid',
                    'Valid?',
                    this.state.validOptions
                  )}
                  <div className='form-group'>
                    {!showDeleteConfirm && (
                      <>
                        {this.renderButton(
                          'Update',
                          'btn-success btn-sm float-end mb-3'
                        )}
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          color='danger'
                          onClick={() => this.handleDeleteBtnClick()}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {showDeleteConfirm && (
                      <>
                        <Button
                          className='mb-3 float-end'
                          size='sm'
                          outline
                          color='secondary'
                          onClick={() => this.handleDeleteConfirmBtnNoClick()}
                        >
                          No
                        </Button>
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          outline
                          color='danger'
                          onClick={() => this.handleDeleteConfirmBtnYesClick()}
                        >
                          Yes
                        </Button>
                        <span className='text-danger me-2 mb-3 float-end'>
                          Are you sure you want to delete this?
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default EditGreenNCAPForm;
