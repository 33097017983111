import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Button, Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';

class AddEuroNCAPForm extends Form {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      data: {
        modelId: this.props.modelId,
        url: '',
        starRating: '',
        date: new Date(),
        adultOccupantRating: '',
        childOccupantRating: '',
        vulnerabaleRoadUsersRating: '',
        safetyAssistRating: '',
        safetyTestVideoUrl: '',
        isValid: '',
        expiryDate: new Date('2030/01/01'),
      },
      validOptions: [
        { _id: 'edit-yes', name: 'Yes' },
        { _id: 'edit-no', name: 'No' },
      ],
      errors: {},
    };
  }

  schema = {
    modelId: Joi.string(),
    url: Joi.string().min(10).max(2048).required().label('Review url'),
    starRating: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(5)
      .label('Star rating'),
    date: Joi.date().required().label('Rating date'),
    adultOccupantRating: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(100)
      .label('Adult occupant rating'),
    childOccupantRating: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(100)
      .label('Child occupant rating'),
    vulnerabaleRoadUsersRating: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(100)
      .label('Vulnerable road users rating'),
    safetyAssistRating: Joi.number()
      .integer()
      .required()
      .min(0)
      .max(100)
      .label('Safety assist rating'),
    safetyTestVideoUrl: Joi.string()
      .min(0)
      .max(2048)
      .allow('')
      .allow(null)
      .optional()
      .label('Safety test video url'),
    isValid: Joi.string().required().label('Valid'),
    expiryDate: Joi.date().required().label('Expiry date'),
  };

  async toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  doSubmit = async () => {
    let { data } = this.state;
    data.isValid === 'edit-yes'
      ? (data.isValid = true)
      : (data.isValid = false);

    data.starRating = parseInt(data.starRating);
    data.adultOccupantRating = parseInt(data.adultOccupantRating);
    data.childOccupantRating = parseInt(data.childOccupantRating);
    data.vulnerabaleRoadUsersRating = parseInt(data.vulnerabaleRoadUsersRating);
    data.safetyAssistRating = parseInt(data.safetyAssistRating);

    if (data.safetyTestVideoUrl.trim().length === 0)
      data.safetyTestVideoUrl = ' ';

    this.setState({
      data: {
        modelId: this.props.modelId,
        url: '',
        starRating: '',
        date: new Date(),
        adultOccupantRating: '',
        childOccupantRating: '',
        vulnerabaleRoadUsersRating: '',
        safetyAssistRating: '',
        safetyTestVideoUrl: '',
        isValid: '',
        expiryDate: new Date('2030/01/01'),
      },
    });
    this.props.onAdd(data);
    this.toggle();
  };

  handleCancelBtnClick = () => {
    this.props.onCancel();
  };

  handleDateChange = (date) => {
    let newData = { ...this.state.data };
    newData.date = date;
    this.setState({ data: newData });
  };

  handleExpiryDateChange = (expiryDate) => {
    let newData = { ...this.state.data };
    newData.expiryDate = expiryDate;
    this.setState({ data: newData });
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <Card className='mt-3'>
          <CardHeader className='card-header-review'>
            <Button
              color=''
              onClick={this.toggle}
              style={{ textAlign: 'left' }}
              block
            >
              <h5>Euro NCAP report</h5>
            </Button>
          </CardHeader>
          <Collapse isOpen={this.state.collapse}>
            <CardBody>
              <form className='mb-4' onSubmit={this.handleSubmit}>
                <div className='col'>
                  {this.renderInput('url', 'Review url')}
                  {this.renderInput('starRating', 'Star rating')}
                  <div className='form-group'>
                    <label>Rating date </label>
                    <DatePicker
                      selected={data.date}
                      dateFormat='dd/MM/yyyy'
                      className='form-control'
                      onChange={this.handleDateChange}
                    />
                  </div>
                  {this.renderInput(
                    'adultOccupantRating',
                    'Adult occupant rating'
                  )}
                  {this.renderInput(
                    'childOccupantRating',
                    'Child occupant rating'
                  )}
                  {this.renderInput(
                    'vulnerabaleRoadUsersRating',
                    'Vulnerable road users rating'
                  )}
                  {this.renderInput(
                    'safetyAssistRating',
                    'Safety assist rating'
                  )}
                  {this.renderInput(
                    'safetyTestVideoUrl',
                    'Safety test video url'
                  )}
                  {this.renderSelect(
                    'isValid',
                    'Valid?',
                    this.state.validOptions
                  )}
                  <div className='form-group'>
                    <label>Expiry date (only applies if Valid is set to No)</label>
                    <DatePicker
                      selected={data.expiryDate}
                      dateFormat='dd/MM/yyyy'
                      className='form-control'
                      onChange={this.handleExpiryDateChange}
                    />
                  </div>
                  <div className='form-group'>
                    {this.renderButton(
                      'Save',
                      'btn-success btn-sm float-end mb-3'
                    )}
                    <Button
                      className='me-2 mb-3 float-end'
                      size='sm'
                      onClick={() => this.handleCancelBtnClick()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default AddEuroNCAPForm;
