import React, { useState, useEffect } from 'react';
// import { Button } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Parser } from 'json2csv';

export default function ReportAllReviews() {
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [reviewSources, setReviewSources] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [assemblingReviews, setAssemblingReviews] = useState(true);

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  // const {
  //   loading: loadingManModelsReviewSources,
  //   error: errorManModelsReviewSources,
  //   data: dataManModelsReviewSources,
  // } = useQuery(gql`
  //   query {
  //     manufacturers(
  //       query: { isPublished_ne: false }
  //       sortBy: NAME_ASC
  //       limit: 100
  //     ) {
  //       _id
  //       name
  //       isPublished
  //     }
  //     models(query: { isActive_ne: false }, limit: 1000) {
  //       _id
  //       manufacturerId
  //       name
  //       year
  //     }
  //     reviewsources(query: { isActive: true }, limit: 200) {
  //       _id
  //       name
  //     }
  //   }
  // `);

  const {
    loading: loadingManModelsReviewSources,
    error: errorManModelsReviewSources,
    data: dataManModelsReviewSources,
  } = useQuery(gql`
    query {
      manufacturers(
        query: { isPublished_ne: false }
        sortBy: NAME_ASC
        limit: 200
      ) {
        _id
        name
        isPublished
      }
      models(limit: 1000) {
        _id
        manufacturerId
        name
        year
        prodStatus
      }
      reviewsources(query: { isActive: true }, limit: 200) {
        _id
        name
      }
    }
  `);

  const {
    loading: loadingReviews,
    error: errorReviews,
    data: dataReviews,
  } = useQuery(gql`
    query {
      reviews(limit: 20000, sortBy: DATE_DESC) {
        date
        modelId
        modelReviewed
        rating
        author
        reviewSourceId
        inUK
      }
    }
  `);

  function assembleReviewsforExport(reviewList) {
    console.log('Start assembling reviews...', reviewList.length);

    // TODO: Assemble....

    var content = 'data:text/csv;charset=utf-8,';

    const reviewListClone = JSON.parse(JSON.stringify(reviewList));
    // console.log(reviewListClone);

    reviewListClone.map((review) => {
      review.reviewDate = new Date(review.date).toLocaleDateString();

      review.reviewModelName = models.filter(function (element) {
        return review.modelId === element._id;
      })[0].name;

      if(review.reviewModelName === '#1') review.reviewModelName = 'Hashtag 1'
      if(review.reviewModelName === '#2') review.reviewModelName = 'Hashtag 2'
      if(review.reviewModelName === '#3') review.reviewModelName = 'Hashtag 3'
      if(review.reviewModelName === '#4') review.reviewModelName = 'Hashtag 4'
      if(review.reviewModelName === '#5') review.reviewModelName = 'Hashtag 5'

      // console.log(review.reviewModelName);

      review.reviewModelYear = models.filter(function (element) {
        return review.modelId === element._id;
      })[0].year;

      review.manufacturerId = models.filter(function (element) {
        return review.modelId === element._id;
      })[0].manufacturerId;

      review.prodStatus = models.filter(function (element) {
        return review.modelId === element._id;
      })[0].prodStatus;

      review.reviewManufacturerName = manufacturers.filter(function (element) {
        return review.manufacturerId === element._id;
      })[0].name;

      review.reviewAuthor = review.author;

      review.reviewSource = reviewSources.filter(function (element) {
        return review.reviewSourceId === element._id;
      })[0].name;

      if (review.inUK) {
        review.reviewedInUK = 'Yes';
      } else {
        review.reviewedInUK = 'No';
      }

      return true;
    });

    const fields = [
      { label: 'Make', value: 'reviewManufacturerName' },
      { label: 'Model', value: 'reviewModelName' },
      { label: 'Model Year', value: 'reviewModelYear' },
      { label: 'Prod Status', value: 'prodStatus' },
      { label: 'Variant', value: 'modelReviewed' },
      { label: 'Review Source', value: 'reviewSource' },
      { label: 'Review Rating', value: 'rating' },
      { label: 'Review Date', value: 'reviewDate' },
      { label: 'Author', value: 'reviewAuthor' },
      { label: 'UK Review', value: 'reviewedInUK' },
    ];
    // const fields = [
    //   { label: 'Make', value: 'reviewManufacturerName' },
    //   { label: 'Model Year', value: 'reviewModelYear' },
    //   { label: 'Variant', value: 'modelReviewed' },
    //   { label: 'Review Source', value: 'reviewSource' },
    //   { label: 'Review Rating', value: 'rating' },
    //   { label: 'Review Date', value: 'reviewDate' },
    //   { label: 'UK Review', value: 'reviewedInUK' },
    // ];

    const opts = { fields };
    const parser = new Parser(opts);
    const csv = parser.parse(reviewListClone);
    content += csv;

    console.log('ready to download');
    window.open(encodeURI(content));

    setAssemblingReviews(false);
  }

  useEffect(() => {
    let modelIds = [];
    let activeReviewSourceIds = [];
    let newReviewList = [];

    if (loadingManModelsReviewSources === false && dataManModelsReviewSources) {
      setManufacturers(dataManModelsReviewSources.manufacturers);
      setModels(dataManModelsReviewSources.models);
      setReviewSources(dataManModelsReviewSources.reviewsources);

      modelIds = dataManModelsReviewSources.models.reduce((acc, model) => {
        acc.push(model._id);
        return acc;
      }, []);

      activeReviewSourceIds = dataManModelsReviewSources.reviewsources.reduce(
        (acc, reviewsource) => {
          acc.push(reviewsource._id);
          return acc;
        },
        []
      );
    }

    if (loadingReviews === false && dataReviews) {
      if (modelIds.length > 0) {
        newReviewList = dataReviews.reviews
          .filter((review) => modelIds.includes(review.modelId))
          .filter((review) =>
            activeReviewSourceIds.includes(review.reviewSourceId)
          );
      }

      setReviews(newReviewList);
      assembleReviewsforExport(newReviewList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingManModelsReviewSources,
    dataManModelsReviewSources,
    loadingReviews,
    dataReviews,
  ]);

  if (errorManModelsReviewSources) {
    return <div>encountered an error: {errorManModelsReviewSources}</div>;
  }

  if (errorReviews) {
    return <div>encountered an error: {errorReviews}</div>;
  }

  return (
    <>
      <p color='primary'>Running All reviews report...</p>
      {loadingManModelsReviewSources ? (
        <>
          <p>
            Step 1 of 3: Pulling in active manufacturers, models and review
            sources...
          </p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 1 of 3: Active manufacturers, models and review sources loaded.
          </p>
          <p>Pulled {manufacturers.length} published manufacturers</p>
          <p>Pulled {models.length} active models</p>
          <p>Pulled {reviewSources.length} active review sources</p>
        </>
      )}
      {loadingReviews ? (
        <>
          <p>Step 2 of 3: Gathering reviews filtered by active models...</p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>Step 2 of 3: All reviews loaded.</p>
          <p>Pulled {reviews.length} reviews</p>
        </>
      )}
      {assemblingReviews ? (
        <>
          <p>Step 3 of 3: Assembling reviews for export...</p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 3 of 3: All reviews data ready — file downloading...
          </p>
        </>
      )}
    </>
  );
}
