import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import Pagination from './common/pagination';
import ReviewSourcesTable from './reviewSourcesTable';
import AddReviewSourceForm from './addReviewSourceForm';
import EditReviewSourceForm from './editReviewSourceForm';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  GET_REVIEWSOURCES,
  ADD_REVIEWSOURCE,
  UPDATE_REVIEWSOURCE,
  DELETE_REVIEWSOURCE,
} from '../graphql/reviewSourcesMutations';

export default function ConfigureReviewSources() {
  const pageSize = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewSources, setReviewSources] = useState([]);
  const [showAddReviewSourceForm, setShowAddReviewSourceForm] = useState(true);
  const [reviewSourceIdEditing, setReviewSourceIdEditing] = useState('');
  const [sortColumn, setSortColumn] = useState({ path: 'name', order: 'asc' });

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const { loading, error, data, refetch: refetchReviewSources } = useQuery(
    GET_REVIEWSOURCES
  );

  const [
    addReviewSource,
    { loading: mutationAddLoading, error: mutationAddError },
  ] = useMutation(ADD_REVIEWSOURCE);

  const [
    updateReviewSource,
    { loading: mutationReplaceLoading, error: mutationUpdateError },
  ] = useMutation(UPDATE_REVIEWSOURCE);

  const [
    deleteReviewSource,
    { loading: mutationDeleteLoading, error: mutationDeleteError },
  ] = useMutation(DELETE_REVIEWSOURCE);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handleSort(sortColumn) {
    setSortColumn(sortColumn);
  }

  function handleAddButtonClick(result) {
    setShowAddReviewSourceForm(result);
  }

  function handleEditCancel() {
    setReviewSourceIdEditing('');
  }

  function handleClickToEdit(reviewSourceId) {
    setReviewSourceIdEditing(reviewSourceId);
  }

  async function handleAddReviewSource(data) {
    const result = await addReviewSource({ variables: data });
    console.log('result: ', result);

    refetchReviewSources();
    setShowAddReviewSourceForm(true);
    toast.success('Review source successfully added');
  }

  async function handleSaveReviewSource(data) {
    const result = await updateReviewSource({
      variables: {
        reviewsourceId: data._id,
        name: data.name,
        logo: data.logo,
        isActive: data.isActive,
        displayReview: data.displayReview,
        displayReviewScores: data.displayReviewScores,
      },
    });

    console.log('result: ', result);

    refetchReviewSources();
    setReviewSourceIdEditing('');
    setShowAddReviewSourceForm(true);
    toast.success('Review source successfully updated');
  }

  async function handleDelete(reviewSourceId) {
    const result = await deleteReviewSource({
      variables: { reviewsourceId: reviewSourceId },
    });

    console.log('deleting: ', reviewSourceId);
    console.log('result: ', result);

    refetchReviewSources();
    setReviewSourceIdEditing('');
    toast.success('Review source successfully deleted');
  }

  useEffect(() => {
    if (loading === false && data) {
      setReviewSources(data.reviewsources);
    }
  }, [loading, data]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (mutationAddError) return <p>Error: {mutationAddError}</p>;
  if (mutationUpdateError) return <p>Error: {mutationUpdateError}</p>;
  if (mutationDeleteError) return <p>Error: {mutationDeleteError}</p>;

  const sortedReviewSources = _.orderBy(
    reviewSources,
    [sortColumn.path],
    [sortColumn.order]
  );

  const totalCount = sortedReviewSources.length;

  return (
    <>
      <p>
        The current review sources are listed below. You can edit the names,
        make them active or inactive (applies across ALL vehicles) or add a new
        source.{` `}
        <span className='text-danger'>
          <em>
            Deleting a review source will delete ALL associated data. There is
            no undo, so please take care when choosing to delete.
          </em>
        </span>
      </p>
      {reviewSourceIdEditing === '' ? (
        <AddReviewSourceForm
          showAddReviewSourceForm={showAddReviewSourceForm}
          buttonTitle='+ Add a new review source'
          onButtonClick={handleAddButtonClick}
          onAdd={(data) => handleAddReviewSource(data)}
        />
      ) : (
        <EditReviewSourceForm
          reviewSourceId={reviewSourceIdEditing}
          reviewSource={
            reviewSources.filter(
              (reviewSource) => reviewSource._id === reviewSourceIdEditing
            )[0]
          }
          onCancel={handleEditCancel}
          onSave={(data) => handleSaveReviewSource(data)}
          // onSave={handleEditSave}
          onDelete={(reviewSourceId) => handleDelete(reviewSourceId)}
        />
      )}
      {loading ||
      mutationAddLoading ||
      mutationReplaceLoading ||
      mutationDeleteLoading ? (
        <ClipLoader
        loading={true}
        cssOverride={cssoverride}
      />
      ) : (
        <>
          <ReviewSourcesTable
            reviewSources={sortedReviewSources}
            sortColumn={sortColumn}
            onSort={handleSort}
            inEditMode={
              reviewSourceIdEditing === '' && showAddReviewSourceForm === true
            }
            onClickToEdit={handleClickToEdit}
          />
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            itemsCount={totalCount}
            pageSize={pageSize}
          />
        </>
      )}
    </>
  );
}
