import React, { useState } from 'react';
import ReportAllReviews from './reportAllReviews';
import ReportRunningCosts from './reportRunningCosts';
import ReportReviewSourceTables from './reportReviewSourceTables';

export default function ViewReports() {
  const [runAllReviewsReport, setRunAllReviewsReport] = useState(false);
  const [runRunningCostsReport, setRunRunningCostsReport] = useState(false);
  const [runReviewSourceTablesReport, setRunReviewSourceTablesReport] = useState(false);

  function runAllRevsReport() {
    setRunAllReviewsReport(true);
  }
  function runRunCostsReport() {
    setRunRunningCostsReport(true);
  }
  function runRevSourceTablesReport() {
    setRunReviewSourceTablesReport(true);
  }

  return (
    <>
      <p>
        Click on a report button below to run a report.
        {` `}
      </p>
      {(!runAllReviewsReport || !runRunningCostsReport) && (
        <button className='btn btn-primary' onClick={() => runAllRevsReport()}>
          All Reviews
        </button>
      )}
      {runAllReviewsReport && <ReportAllReviews />}
      <br/>
      <br/>
      {!runReviewSourceTablesReport && (
        <button className='btn btn-primary' onClick={() => runRevSourceTablesReport()}>
          Review Source Tables
        </button>
      )}
      {runReviewSourceTablesReport && <ReportReviewSourceTables />}
      <br/>
      <br/>
      {!runRunningCostsReport && (
        <button className='btn btn-primary' onClick={() => runRunCostsReport()}>
          All Expert Rating Data 
        </button>
      )}
      {runRunningCostsReport && <ReportRunningCosts />}
    </>
  );
}
