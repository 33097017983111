import React, { Component } from 'react';
import WPAPI from 'wpapi';
import _ from 'lodash';

class ManagePluginSites extends Component {
  state = {
    siteAddress: 'http://remote-test-site.local',
    username: 'frankbarnhouse',
    password: 's6hr,g,7dopL(7AK',
    wpAPI: {},
    connectStatus: false,
    categorySlugs: ['prism-post', 'prism-post-reviews'],
    categoryNames: [],
    categoryIDs: [],
    categorySlugFound: false,
    filteredPostsArray: [],
    allPostArray: []
  };

  async componentDidMount() {
    // const wp = new WPAPI({
    //   endpoint: 'http://compareallvans.co.uk/wp-json',
    //   username: 'Frank Barnhouse',
    //   password: 'C*Y$ZW@rYYT8u%W3'
    // });
    const wp = await new WPAPI({
      endpoint: this.state.siteAddress + '/wp-json',
      username: this.state.username,
      password: this.state.password,
      auth: true
    });

    this.setState({ wpAPI: wp });
  }

  handleConnectButtonClick = async () => {
    // Establish connection
    const users = await this.state.wpAPI.users();
    if (users.length > 0) {
      this.setState({ connectStatus: true });
    }

    // Ensure category exists on site
    let prismPostCategoryIDs = [];
    let prismPostCategoryNames = [];
    let prismPostCategoryFound = false;

    if (this.state.connectStatus) {
      const categories = await this.state.wpAPI.categories();
      categories.map(category => {
        if (_.includes(this.state.categorySlugs, category.slug)) {
          prismPostCategoryIDs.push(category.id);
          prismPostCategoryNames.push(category.name);
          prismPostCategoryFound = true;
        }
        return null;
      });

      this.setState({
        categorySlugFound: prismPostCategoryFound,
        categoryNames: prismPostCategoryNames,
        categoryIDs: prismPostCategoryIDs
      });
    }
  };

  handleGetPostsButtonClick = async () => {
    // Pull posts with category if the category slug is found
    if (this.state.connectStatus && this.state.categorySlugFound) {
      const categoryPosts = await this.state.wpAPI
        .posts()
        .categories(this.state.categoryIDs);

      this.setState({ allPostArray: categoryPosts });
    }

    // console.log(this.state.allPostArray[0].id);

    // const currentPost = await wp
    //   .posts()
    //   .id('6')
    //   .context('edit');
    // const originalContent = currentPost.content.raw;
    // // console.log(originalContent);
    // const contentStartIndex = originalContent.search(
    //   '<!-- wp:tce/prism-auto-importer-reviews {'
    // );
    // const contentEndIndex =
    //   originalContent.search('<!-- /wp:tce/prism-auto-importer-reviews -->') -
    //   44;
    // console.log('content start: ' + contentStartIndex);
    // console.log('content end: ' + contentEndIndex);
    // const reviewBlock = originalContent.substr(
    //   contentStartIndex,
    //   contentEndIndex
    // );
    // // console.log(reviewBlock);
    // const objectStartIndex = contentStartIndex + 40;
    // const objectEndIndex =
    //   originalContent.search('} -->') - (objectStartIndex - 1);
    // console.log('object start: ' + objectStartIndex);
    // console.log('object end: ' + objectEndIndex);
    // const reviewObjectBlock = originalContent.substr(
    //   objectStartIndex,
    //   objectEndIndex
    // );
    // const reviewObject = JSON.parse(reviewObjectBlock);
    // // console.log(reviewObject);
    // console.log('currentManufacturerId: ' + reviewObject.currentManufacturerId);
    // console.log('currentModelId: ' + reviewObject.currentModelId);
  };

  handleRefreshPostsButtonClick = async () => {
    if (this.state.connectStatus && this.state.categorySlugFound) {
      const categoryPosts = await this.state.wpAPI
        .posts()
        .categories(this.state.categoryIDs);

      this.setState({ allPostArray: categoryPosts });
    }
  };

  handleCheckPostButtonClick = async postID => {
    let postModel = {};
    const thisPostObject = await this.state.wpAPI
      .posts()
      .id(postID)
      .context('edit');

    postModel.id = thisPostObject.id;
    postModel.title = thisPostObject.title.rendered;
    postModel.rawContent = thisPostObject.content.raw;

    console.log(postModel);
  };

  render() {
    const {
      siteAddress,
      username,
      categorySlugs,
      categoryNames,
      connectStatus,
      categorySlugFound,
      allPostArray
    } = this.state;

    return (
      <>
        <div>
          Create a category uinque to posts using the plugin. The category name
          can be anything, but set the category slug name as 'prism-post'.
        </div>
        <hr />
        <p>
          Site address: <b>{siteAddress}</b>
          <br />
          Username: <b>{username}</b>
          <br />
          Category slugs: <b>{categorySlugs.join(', ')}</b>
        </p>
        <hr />
        {!connectStatus && (
          <button onClick={() => this.handleConnectButtonClick()}>
            Connect
          </button>
        )}
        {connectStatus && (
          <p className='text-success mb-0'>
            <b>Connected!</b>
          </p>
        )}
        {categorySlugFound && (
          <p className='text-success'>
            Categories found as <b>{categoryNames.join(', ')}</b>
          </p>
        )}
        <hr />
        {connectStatus && _.isEmpty(allPostArray) && (
          <button onClick={() => this.handleGetPostsButtonClick()}>
            Get Posts
          </button>
        )}
        {!_.isEmpty(allPostArray) && (
          <div style={{ display: 'inline-flex' }}>
            <h4>{allPostArray.length + ` Posts found`}</h4>
            <button
              onClick={() => this.handleRefreshPostsButtonClick()}
              style={{ height: 'fit-content', marginLeft: '15px' }}
            >
              Refresh
            </button>
          </div>
        )}
        <ul className='manage-post-container'>
          {!_.isEmpty(allPostArray) &&
            allPostArray.map(post => (
              <li key={post.id} className='manage-post-block'>
                <a href={post.link} target='_blank' rel='noopener noreferrer'>
                  {post.title.rendered}
                </a>
                <div>Modified: {post.modified}</div>
                <button
                  onClick={() => this.handleCheckPostButtonClick(post.id)}
                >
                  Check
                </button>
              </li>
            ))}
        </ul>
      </>
    );
  }
}
export default ManagePluginSites;
