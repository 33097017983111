// This must be the first line in src/index.js
import "react-app-polyfill/ie11";
// -----------
import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { useRealmApp, RealmAppProvider } from "./RealmApp";
import LoginScreen from "./components/LoginScreen";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import * as Realm from "realm-web";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./custom.css";

export const APP_ID = "prismapimanager-bbtyq";

const container = document.getElementById("app");
const root = createRoot(container);

const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <LoginScreen />;
};

// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);

// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData();
  }
  return app.currentUser.accessToken;
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://eu-west-1.aws.services.cloud.mongodb.com/api/client/v2.0/app/prismapimanager-bbtyq/graphql`,
    // uri: `https://services.cloud.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache(),
});

root.render(
  <RealmAppProvider appId={APP_ID}>
    <RequireLoggedInUser>
      <ApolloProvider client={client}>
        <BrowserRouter forceRefresh={true}>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </RequireLoggedInUser>
  </RealmAppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
