import React, { Component } from 'react';
import Table from './common/table';
import { Button } from 'reactstrap';

class ModelsTable extends Component {
  handleEditBtnClick(modelId) {
    this.props.onClickToEdit(modelId);
  }

  columns = [
    {
      path: 'name',
      label: 'Model name',
      class: 'align-middle',
      content: (model) => (
        <Button
          color='link'
          className={model.isActive ? 'text-success' : ''}
          onClick={() => this.handleEditBtnClick(model._id)}
          disabled={!this.props.inEditMode}
        >
          {model.name}
        </Button>
      ),
    },
    {
      path: 'vehicleType',
      label: 'Type',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.isActive
                ? 'text-success'
                : ''
              : 'text-muted'
          }
        >
          {model.vehicleTypes.join(', ')}
        </span>
      ),
    },
    {
      path: 'year',
      label: 'Year',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.isActive
                ? 'text-success'
                : ''
              : 'text-muted'
          }
        >
          {model.year}
        </span>
      ),
    },
    {
      path: 'prodStatus',
      label: 'Prod. Status',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.prodStatus === 'current'
                ? 'text-success'
                : ''
              : 'text-muted'
          }
        >
          {model.prodStatus === null ? null : model.prodStatus.toUpperCase()}
        </span>
      ),
    },
    {
      path: 'isActive',
      label: 'Status',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.isActive
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {model.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    // {
    //   path: 'reviews',
    //   label: 'Reviews',
    //   class: 'text-center align-middle',
    //   content: model => (
    //     <span
    //       className={
    //         this.props.inEditMode
    //           ? model.isActive
    //             ? 'text-success'
    //             : 'text-danger'
    //           : 'text-muted'
    //       }
    //     >
    //       {model.reviews.length}
    //     </span>
    //   )
    // },
    {
      path: 'isComplete',
      label: 'Complete',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.isComplete
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {model.isComplete ? 'Complete' : 'Incomplete'}
        </span>
      ),
    },
    {
      path: 'isPublished',
      label: 'Published',
      class: 'text-center align-middle',
      content: (model) => (
        <span
          className={
            this.props.inEditMode
              ? model.isPublished
                ? 'text-success'
                : 'text-danger'
              : 'text-muted'
          }
        >
          {model.isPublished ? 'Yes' : 'No'}
        </span>
      ),
    },
  ];

  render() {
    const { models, onSort, sortColumn } = this.props;
    if (models.length === 0)
      return (
        <>
          <div className='row mt-3'>
            <div className='col'>
              <p>
                There are no models of the selected manufactuer in the database
              </p>
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className='row mt-3'>
          <div className='col'>
            <p>
              Showing {models.length} {models[0].manufacturer} model
              {models.length > 1 ? 's' : ''} in the database
            </p>
            <Table
              columns={this.columns}
              data={models}
              sortColumn={sortColumn}
              onSort={onSort}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ModelsTable;
